import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrandContext } from '../context/BrandContext/context';
import { SiteContext } from '../context/SiteContext/context';
import { DataContext } from '../context/DataContext/context';
import CoverageMapComponent from '../components/CoverageMapComponent';
import { head } from 'lodash';
import FeasibilityList from '../components/FeasibilityList';
import { Paper } from '@material-ui/core';
import FeasibilityViewTotals from '../components/FeasibilityViewTotals';
import { Typography } from '@material-ui/core';
import Utils from '../utils/Utils';

import Logging from '../services/Logging';

let GoogleDefaultInfoWindow = require('../utils/GoogleDefaultInfoWindow');
const ReactDOMServer = require('react-dom/server');

const currencyFormatter = Utils.formatCurrency()

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        // display:'flex'
    },
    rootNav: {
        height: '100%',
        // height: '88vh',
        // display:'flex'
    },
    coverageMap: {
        // height: '100%',
        height: '45%',
        width: '100%',
        position: 'relative'
        // width: '45%',
        // minWidth: '500px'
    },
    feasibilityList: {
        width: '100%',
        height: '53%',
        padding: '10pt',
        background: theme.palette.background
    }
}));

export default function SingleFeasibilityContainer(props) {
    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const { data, dispatchData } = useContext(DataContext)
    const classes = useStyles()

    const [headers, setHeaders] = useState([
        'Network',
        'Site',
        'Latitude',
        'Longitude',
        'PoP',
        'PoP Latitude',
        'PoP Longitude',
        'LoS (m)',
        'Built Distance (m)',
        'Total NRC',
        'Total MRC'
    ])
    const [totals, setTotals] = useState([])
    const [columns, setColumns] = useState([
        { title: 'Network', value: 'network' },
        { title: 'Site', value: 'site' },
        { title: 'Bandwidth', value: 'bandwidth' },
        { title: 'Term', value: 'term' },
        { title: 'Latitude', value: 'latitude' },
        { title: 'Longitude', value: 'longitude' },
        { title: 'PoP', value: 'pop' },
        { title: 'PoP Latitude', value: 'popLatitude' },
        { title: 'PoP Longitude', value: 'popLongitude' },
        { title: 'LoS (m)', value: 'los' },
        { title: 'Built Distance (m)', value: 'buildDistance' },
    ])
    const [items, setItems] = useState([])
    // const [feasibility, setFeasibility] = useState()
    const [loading, setLoading] = useState(false)
    const [formattedAddress, setFormattedAddress] = useState('')
    const [message, setMessage] = useState()

    const req = useRef({
        formatted_address: '',
    })

    const providerNames = {
        'wioccmetrolines': 'WIOCC Metro Lines',
        'wioccnationallines': 'WIOCC National Lines',
        'wioccpops': 'WIOCC PoPs'
    }


    useEffect(() => {
        // prepareColumns()
    }, [])

    const prepareColumns = () => {
        setLoading(true)
        if (!headers || head.length === 0) return;
        let newHeaders = headers
        let newColumns = columns
        if (!headers.includes('address')) {
            newHeaders.push('address')
            setHeaders(newHeaders)
        }

        newHeaders.forEach(header => {
            if (header.toLowerCase() !== "service" && header.toLowerCase() !== "slug" && header.toLowerCase() !== "name" && header !== 'address')
                newColumns.push({ label: header, name: header.replace(/\s/g, '') });
            else if (header === 'address')
                newColumns.push({ label: header, name: header.replace(/\s/g, ''), options: { display: false, empty: true } });
        });
        setColumns(newColumns)
        setLoading(false)
    }

    const mapProviderName = (provider) => {
        if (providerNames[provider]) return providerNames[provider]
        return provider
    }

    const prepareItems = (feasibility) => {
        setLoading(true)
        let newItems = []

        if(feasibility && feasibility.message) setMessage(feasibility.message)
        if (!feasibility || !feasibility.success || !feasibility.feasible) {
            setLoading(false)
            setItems([])
            return
        }
        setMessage(null)

        if (feasibility.siteA) {
            newItems.push({
                network: feasibility.product && feasibility.siteA && feasibility.siteA.feasible ? feasibility.product : 'Not Feasible',
                bandwidth: feasibility.bandwidth ? feasibility.bandwidth : '',
                term: feasibility.term ? feasibility.term : '',
                site: 'Site A',
                latitude: feasibility.siteA.location ? +feasibility.siteA.location.latitude.toFixed(6) : '',
                longitude: feasibility.siteA.location ? +feasibility.siteA.location.longitude.toFixed(6) : '',
                pop: feasibility.siteA.pop ? feasibility.siteA.pop.name : '',
                popLatitude: feasibility.siteA.pop && feasibility.siteA.pop.location ? +feasibility.siteA.pop.location.latitude.toFixed(6) : '',
                popLongitude: feasibility.siteA.pop && feasibility.siteA.pop.location ? +feasibility.siteA.pop.location.longitude.toFixed(6) : '',
                los: feasibility.siteA.los ? parseFloat(feasibility.siteA.los.toFixed(3)) : '',
                buildDistance: feasibility.siteA.built_distance ? parseFloat(feasibility.siteA.built_distance.toFixed(3)) : '',
            })
        }
        if (feasibility.siteB) {
            newItems.push({
                network: feasibility.product && feasibility.siteB && feasibility.siteB.feasible ? feasibility.product : 'Not Feasible',
                bandwidth: feasibility.bandwidth ? feasibility.bandwidth : '',
                term: feasibility.term ? feasibility.term : '',
                site: 'Site B',
                latitude: feasibility.siteB.location ? +feasibility.siteB.location.latitude.toFixed(6) : '',
                longitude: feasibility.siteB.location ? +feasibility.siteB.location.longitude.toFixed(6) : '',
                pop: feasibility.siteB.pop ? feasibility.siteB.pop.name : '',
                popLatitude: feasibility.siteB.pop && feasibility.siteB.pop.location ? +feasibility.siteB.pop.location.latitude.toFixed(6) : '',
                popLongitude: feasibility.siteB.pop && feasibility.siteB.pop.location ? +feasibility.siteB.pop.location.longitude.toFixed(6): '',
                los: feasibility.siteB.los ? parseFloat(feasibility.siteB.los.toFixed(3)) : '',
                buildDistance: feasibility.siteB.built_distance ? parseFloat(feasibility.siteB.built_distance.toFixed(3)) : '',
            })
        }

        let newTotals = []
        // if (feasibility.total_nrc) {
            newTotals.push({
                title: 'Total NRC',
                value: `${feasibility?.siteA?.currency?.abbreviation || ''} ${currencyFormatter.format(feasibility.total_nrc.toFixed(2))}`
            })
        // }

        // if (feasibility.total_mrc) {
            newTotals.push({
                title: 'Total MRC',
                value: `${feasibility?.siteA?.currency?.abbreviation || ''} ${currencyFormatter.format(feasibility.total_mrc.toFixed(2))}`
            })
        // }
        setItems(newItems)
        setTotals(newTotals)
        setLoading(false)

        const auditItems = Utils.wioccAuditLoggingParser(newItems, newTotals, columns)

        const loggingRequest = {
            siteA: {
                formatted_address: req.current.formatted_address,
                latitude: feasibility.siteA.location.latitude ? `${feasibility.siteA.location.latitude.toFixed(6)}`: '',
                longitude: feasibility.siteA.location.longitude ?`${feasibility.siteA.location.longitude.toFixed(6)}` : '',
            },
            siteB: {
                aggregation_node: feasibility.siteB.pop ? feasibility.siteB.pop.name : '',
                latitude: feasibility.siteB.location.latitude ? `${feasibility.siteB.location.latitude.toFixed(6)}`: '',
                longitude: feasibility.siteB.location.longitude ? `${feasibility.siteB.location.longitude.toFixed(6)}` : '',
            },
            bandwidth: feasibility.bandwidth ? feasibility.bandwidth : '',
            term: feasibility.term ? feasibility.term : '',
            action: 'single-feasibility'
        }
        
        if (auditItems) {
            Logging.auditLog(loggingRequest, { results: auditItems })
        }
    }

    const onAddressChange = (place) => {
        if (!place) return
        if (place.formatted_address) {
            setFormattedAddress(place.formatted_address)
            req.current.formatted_address = place.formatted_address
        } else {
            setFormattedAddress(place)
            req.current.formatted_address = place
        }
        // if (place && place.geometry && place.geometry.location)
        //     req.current.location = `${place.geometry.location.lat()}, ${place.geometry.location.lng()}`
        // else req.current.location = place
        
    }

    const onResultsReceived = async (results) => {
        // prepareColumns(results)
        prepareItems(results)
    }

    return (
        <div className={brand.navbar.show && brand.fullHeight ? classes.rootNav : classes.root}>
            <Paper className={classes.coverageMap}>
                <CoverageMapComponent
                    onAddressChange={onAddressChange}
                    onResultsReceived={onResultsReceived}
                    hidepopup={true}
                    hidelayercontrol={true}
                />
            </Paper>
            <Typography variant='caption'><b>Disclaimer: </b>Pricing quoted is subject to site survey and feasibility.</Typography>
            <Paper className={classes.feasibilityList}>
                <FeasibilityViewTotals
                    columns={columns}
                    rows={items}
                    loading={loading}
                    totals={totals}
                    message={message}
                />
            </Paper>
        </div>
    );
}
