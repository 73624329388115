import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Utils from '../utils/Utils';

const TAX_RATE = 0.07;

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    notFeasible: {
        textAlign: 'center',
        marginTop: '20px'
    }
});


function ccyFormat(num) {
    return `${Utils.formatZAF(num)}`;
}


export default function SpanningTable(props) {
    const classes = useStyles();

    return (<>
        {props.rows && props.rows.length > 0 ?
            <>
                <TableContainer >
                    <Table className={classes.table} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                {
                                    props.columns.map(c => {
                                        return (
                                            <TableCell>{c.title}</TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {props.rows.map((row) => (
                                <TableRow key={row.desc}>
                                    {props.columns.map(c => {
                                        return <TableCell>{row[c.value]}</TableCell>
                                    })}
                                </TableRow>
                            ))}
                            {props.totals.map((row) => {
                                return (
                                    <TableRow>
                                        <TableCell colSpan={props.columns.length - 2} />
                                        <TableCell ><b>{row.title}</b></TableCell>
                                        <TableCell ><b>{ccyFormat(row.value)}</b></TableCell>
                                    </TableRow>)
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            : <>
                {props.message ? <>
                    <TableContainer >
                        <Table className={classes.table} aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    {
                                        props.columns.map(c => {
                                            return (
                                                <TableCell>{c.title}</TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    <Typography className={classes.notFeasible}>
                        {props.message}
                    </Typography>
                </> :
                    <>
                        <TableContainer >
                            <Table className={classes.table} aria-label="spanning table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            props.columns.map(c => {
                                                return (
                                                    <TableCell>{c.title}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        <Typography className={classes.notFeasible}>
                            Not Feasible
                        </Typography>
                    </>}
            </>
        }</>
    );
}
