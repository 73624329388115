import '../modules/Proj4Module';
const Utils = {}
const ZafFormatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
});

Utils.wioccAuditLoggingParser = (data, prices, column) => {
     const parsedDataArray = []; // Use an object instead of an array

    data.forEach((currentObject) => {
      let parsedDetails = {};

      if (currentObject) {
        parsedDetails = Object.entries(currentObject).reduce((parsedObj, detailObject) => {
          let key
          
          column.map((object) => {
            if(object.value === detailObject[0]) {
                key = object.title
            }
          })

          parsedObj[key] = detailObject[1] ? detailObject[1] : '';

          return parsedObj;
        }, {})
      } 

      parsedDataArray.push(parsedDetails)

    });

    prices.forEach((currentObjec) => {
        let parsedDetails = {};

        if (currentObjec) {
            parsedDetails[currentObjec.title] = currentObjec.value
      } 

        parsedDataArray.push(parsedDetails)
    })


    return parsedDataArray

}


Utils.deepMerge = (...objects) => {
    const isObject = (obj) => obj && typeof obj === 'object';

    function deepMergeInner(target, source) {
        Object.keys(source).forEach((key) => {
            const targetValue = target[key];
            const sourceValue = source[key];

            if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
                target[key] = targetValue.concat(sourceValue);
                // let t = targetValue.map((item, i) => Object.assign({}, item, sourceValue[i]));
                // target[key] = targetValue.map((item, i) => Object.assign({}, item, sourceValue[i]));
                // target[key] = [targetValue, sourceValue].reduce((a, b) => a.map((c, i) => Object.assign({}, c, b[i])));
                // let t = [targetValue, sourceValue].map((a, b) => deepMergeInner(a,b));
                // target[key] = t[0].concat(t[1]);
            } else if (isObject(targetValue) && isObject(sourceValue)) {
                target[key] = deepMergeInner(Object.assign({}, targetValue), sourceValue);
            } else {
                target[key] = sourceValue;
            }
        });

        return target;
    }

    if (objects.length < 2) {
        throw new Error('deepMerge: this function expects at least 2 objects to be provided');
    }

    if (objects.some(object => !isObject(object))) {
        throw new Error('deepMerge: all values should be of type "object"');
    }

    const target = objects.shift();
    let source;


    while (source = objects.shift()) {
        deepMergeInner(target, source);
    }

    return target;
}


Utils.reProject = (lat, lng) => {
    if (!window.proj4) throw "proj4 not initialised";

    return window.proj4
        ? window.proj4('EPSG:4326', 'EPSG:900913', [lng, lat])
        : [lng, lat];
}


Utils.getPixelLocation = (latlng, googleMap) => {
    if(!window.google) throw 'Google Maps not initialised'
    if(!googleMap) throw 'Google Maps object is required'

    var scale = Math.pow(2, googleMap.getZoom());
    // The NorthWest corner of the current viewport corresponds
    // to the upper left corner of the map.
    // The script translates the coordinates of the map's center point
    // to screen coordinates. Then it subtracts the coordinates of the
    // coordinates of the map's upper left corner to translate the
    // currentLatLng location into pixel values in the <div> element that hosts the map.
    var nw = new window.google.maps.LatLng(
        googleMap.getBounds().getNorthEast().lat(),
        googleMap.getBounds().getSouthWest().lng()
    );
    // Convert the nw location from geo-coordinates to screen coordinates
    var worldCoordinateNW = googleMap.getProjection().fromLatLngToPoint(nw);
    // Convert the location that was clicked to screen coordinates also
    var worldCoordinate = googleMap.getProjection().fromLatLngToPoint(latlng);
    var pixelLocation = new window.google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
    );
    return pixelLocation;
}

Utils.angleFromCoords = (lat1, lat2, lng1, lng2) => {

    let dlng = lng2-lng1;
    let y = Math.sin(dlng) * Math.cos(lat2);
    let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dlng);
    const brng = Math.atan2(y, x);
   return Math.round((brng*180/Math.PI + 360) % 360); // in degrees
}

Utils.getCurrentDate = (separator = '-') => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
};

Utils.validateCoords = (latitude, longitude) => {
    return (latitude > 90 ||
    latitude < -90 ||
    longitude > 180 ||
    longitude < -180 ||
    latitude === '' ||
    longitude === ''
    )
}

Utils.formatZAF = (num) => {
    if(!num || typeof num != 'number') return 0
    return 'R' + num.toFixed(2).replace('/\d(?=(\d{3})+\.)/g', '$&,')
    // return ZafFormatter.format(num)
}

Utils.formatCurrency = () => {
    return new Intl.NumberFormat()
}

Utils.parseForAdminDataTable = (data, userClaims) => {
  let userLevel = 'user'

  if (userClaims?.roles) {
    if (
      userClaims.roles[process.env.REACT_APP_CONFIG_APP]?.includes('admin')
    ) {
      userLevel = 'admin'
    } else if (
      userClaims.roles[process.env.REACT_APP_CONFIG_APP]?.includes('sysadmin')
    ) {
      userLevel = 'sysadmin'
    } else if (userClaims.roles?.includes('sysadmin')) {
      userLevel = 'sysadmin'
    }
  }

  if (data?.users?.length) {
    return data.users
      .map((user) => {
        if (data.success) {
          const parsedObj = {
            id: user.uid,
            displayName: user.displayName,
            email: user.email,
            isDisabled: user.disabled,
            authorisedApps: user.authorizedApps,
            lastSignIn: user.lastSignIn,
            created: user.createTime,
            roles: user.roles.length ? [...user.roles] : ['User'],
            provider: user.provider
          }

          if (
            (userLevel === 'admin' && !parsedObj.roles.includes('sysadmin')) ||
            userLevel === 'sysadmin'
          ) {
            return parsedObj
          }
        }

        return null
      })
      .filter((item) => item !== null)
  }

  return []
}

// ? Auth checks. This can be optimised once we've got the auth system fully figured out
Utils.isSysAdmin = (userClaims) => {
    // ? Bypass the user auth when sysadmin is working
    if(userClaims?.roles && Array.isArray(userClaims.roles) && userClaims?.roles?.includes('sysadmin')) return true
  
    if (
      !userClaims?.roles?.[process.env.REACT_APP_CONFIG_APP]?.length
    ) {
      return false
    }
  
    if (
      userClaims?.roles[process.env.REACT_APP_CONFIG_APP].includes('sysadmin')
    ) {
      return true
    }
  
    return false
  }
  
  Utils.isAdmin = (userClaims) => {
    // ? Bypass the user auth when sysadmin is working
    if(userClaims?.roles && Array.isArray(userClaims.roles) && userClaims?.roles?.includes('sysadmin')) return true
  
    if ( !userClaims?.roles?.[process.env.REACT_APP_CONFIG_APP].length) {
      return false
    }
  
    if (
      (userClaims?.roles[process.env.REACT_APP_CONFIG_APP].includes('admin') ||
        userClaims?.roles[process.env.REACT_APP_CONFIG_APP].includes('sysadmin'))
    ) {
      return true
    }
  
    return false
  }
  
  Utils.canAccess = (controlLevel, userClaims) => {
    // @ Control level is a string thats basically the page name
  
    // ? Bypass the user auth when sysadmin is working
    if (userClaims?.roles && Array.isArray(userClaims.roles) && userClaims?.roles?.includes('sysadmin')) return true
    if (userClaims?.roles?.[process.env.REACT_APP_CONFIG_APP] && Array.isArray(userClaims.roles[process.env.REACT_APP_CONFIG_APP]) && userClaims?.roles?.[process.env.REACT_APP_CONFIG_APP].includes('sysadmin')) return true
  
    if(controlLevel === 'all' || (Array.isArray(controlLevel) && controlLevel.includes('all'))) return true
    
    if (!(userClaims?.roles?.[process.env.REACT_APP_CONFIG_APP]?.length > 0)) {
      return false
    }
  
    if (
      Array.isArray(userClaims?.roles?.[process.env.REACT_APP_CONFIG_APP]) &&
      controlLevel.some(level => userClaims.roles[process.env.REACT_APP_CONFIG_APP].includes(level))
    ) {
      return true
    }
  
  
    return false
  }

Utils.dataSizeParsing = (num) => {
    if(num < 1000) return `${num} Mb`
    if(num >= 1000) {
        const newNum = num / 1000
        return `${newNum} Gb`
    }
}


export default Utils