import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react'
import firebase from 'firebase/app';
import 'firebase/auth';

import { BrandContext } from '../BrandContext/context'

const AuthContext = createContext()

// const validatePasswordReset = async (sentFirebaseAuth, actionCode, newPassword) => { 
//   await confirmPasswordReset(sentFirebaseAuth, actionCode, newPassword)
// }

const providers = {
  emailProvider: new firebase.auth.EmailAuthProvider(),
  microsoftProvider: new firebase.auth.OAuthProvider('microsoft.com')
};
if (providers.microsoftProvider)
  providers.microsoftProvider.setCustomParameters({
    tenant: process.env.REACT_APP_TENANT_ID,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET
  })

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null)
  const [userClaims, setUserClaims] = useState({})

  const { brand } = useContext(BrandContext)

  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const firebaseApp = brand.authentication && !firebase.apps.length ? firebase.initializeApp(brand.firebaseConfig) : brand.authentication ? firebase.app() : null;//firebase.initializeApp(brand.firebaseConfig);
  const firebaseAppAuth = brand.authentication ? firebaseApp.auth() : null;

  // handle claims for determining access 
  const handleClaims = async (currentUser) => {

    const tokenResult = await currentUser.getIdTokenResult()

    const claims = tokenResult.claims || {}
  
    if((Array.isArray(claims.roles)) && claims.roles.includes('sysadmin')) {
      setUserClaims({...claims, accessToken: tokenResult.token})
      setUser(currentUser)
      
      return
    }

    if(!claims.authorizedApps?.length && currentUser?.providerData?.length) {
      currentUser.providerData.forEach( async provider => {
        if(provider.providerId === 'microsoft.com') {
          
          claims.roles = {
            default: ['user']
          }

          claims.authorizedApps = ['default']
          
          setUserClaims({...claims, accessToken: tokenResult.token})
          setUser(currentUser)

          return
        }
      })
    }
  
    if((!claims?.authorizedApps?.includes(process.env.REACT_APP_CONFIG_APP))) {
      throw new Error('Your account has not been authorised for this environment','auth/environment-not-autorised')
    }

    if(!Array.isArray(claims.roles) && !claims.roles[process.env.REACT_APP_CONFIG_APP]?.length > 0) {
      throw new Error('Your account has not been authorised for this environment','auth/environment-not-autorised')
    }
  
    setUserClaims({...claims, accessToken: tokenResult.token})
    setUser(currentUser)

    return
  }

  const onLoginEmailPassword = useCallback(async (email, password) => {

    const signedInUser = await firebase.auth().signInWithEmailAndPassword(email, password)

    if(signedInUser.user) {
      return await handleClaims(signedInUser.user)
    }
  },[]);

  const onLoginMicrosoft = useCallback( async () => {

    const signInWithProvider = await firebase.auth().signInWithPopup(providers.microsoftProvider)

    if(signInWithProvider.user) {
      return await handleClaims(signInWithProvider.user)
    }
  },[])

  const resetPassword = useCallback(async (newUserPassword, actionCode = '') => {
    await firebase.auth().verifyPasswordResetCode(actionCode)

    await firebase.auth().confirmPasswordReset(actionCode, newUserPassword)

    return
  }, [])

  const resetPasswordLink = useCallback(async (email) => {
    await firebase.auth().sendPasswordResetEmail(email)
  }, [])

  const handleSignOut = useCallback(() => {
    if (brand.authentication){
      firebaseAppAuth.signOut();
      
      setUserClaims(null)
      setUser(null)
    }
  },[]);

  const providedValues = useMemo(
    () => ({ user, userClaims , onLoginMicrosoft, onLoginEmailPassword, handleSignOut, resetPasswordLink, resetPassword}),
    [user, userClaims, onLoginMicrosoft, onLoginEmailPassword, handleSignOut, resetPasswordLink, resetPassword]
  )

  return <AuthContext.Provider value={providedValues}>{children}</AuthContext.Provider>
}
export function useUserAuth() {
  return useContext(AuthContext)
}
