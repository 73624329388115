import React from 'react'

import Dialog from '@mui/material/Dialog'

import DeletePopup from './DeletePopup'
import DisablePopup from './DisablePopup'
import EditPopup from './EditPopup'

const UserManagementPopup = ({
  editingUserDetails,
  onCancelChanges,
  onCreateUser,
  onDeleteUser,
  onDisableUser,
  onSaveUser,
  open,
  popupType,
}) => {

    if(popupType === 'disable'){
      return (
        <Dialog aria-labelledby="form-dialog-title" open={open} onClose={onCancelChanges}>
          <DisablePopup  editingUserDetails={editingUserDetails} onCancelChanges={onCancelChanges} onConfirmChanges={onDisableUser}/>
        </Dialog>
      )
    } 

    if(popupType === 'delete'){
      return (
        <Dialog aria-labelledby="form-dialog-title" open={open} onClose={onCancelChanges}>
          <DeletePopup editingUserDetails={editingUserDetails} onCancelChanges={onCancelChanges} onConfirmChanges={onDeleteUser}/>
      </Dialog>
      )
    }

    if(popupType === 'edit'){
      return (
        <Dialog aria-labelledby="form-dialog-title" open={open} onClose={onCancelChanges}>
          <EditPopup edit editingUserDetails={editingUserDetails} onCancelChanges={onCancelChanges} onConfirmChanges={onSaveUser}/>
        </Dialog>
      )
    } 

    if(popupType === 'create'){
      return (
        <Dialog aria-labelledby="form-dialog-title" open={open} onClose={onCancelChanges}>
          <EditPopup editingUserDetails={editingUserDetails} onCancelChanges={onCancelChanges} onConfirmChanges={onCreateUser}/>
        </Dialog>)
    } 
}

export default UserManagementPopup
