import React, { useContext, useState } from "react";

import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography, makeStyles } from "@material-ui/core";
import { BrandContext } from "../context/BrandContext/context";
import { useUserAuth } from "../context/AuthContext/AuthContext";
import Navbar from "../components/Navbar";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundImage: 'url(./logoBackground.jpg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "60%",
    backgroundColor: '#ffffff'
  },
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  input: {
    background: '#e8f0fe'
  }
}));

export default function ResetPasswordView() {
  const classes = useStyles();

  const queryParameters = new URLSearchParams(window.location.search)
  const oobCode = queryParameters.get("oobCode")

  const { brand } = useContext(BrandContext)
  const { resetPassword } = useUserAuth()

  const [password, setPassword] = useState('')

  const [showPassword, setShowPassword] = useState(false)

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const [resetSuccessful, setResetSuccessful] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleResetPassword = async () => {
    setLoading(true)

    await resetPassword(password, oobCode)
      .then((data) => {

        setResetSuccessful(true)
        setSuccessMessage('Your password has successfully been changed.')
      })
      .catch((error) => {
        const { message, code } = error
        let customMessage = ''

        if (code === 'auth/internal-error') {
          customMessage = `Something wen't wrong. Please request a reset in a minute or two.`
        } else if (code === 'auth/invalid-action-code') {
          customMessage = 'This link is invalid. Please request another reset password link.'
        } else if (code === 'auth/expired-action-code') {
          customMessage = 'This link has expired. Please request another reset password link.'
        } else if (code === 'auth/weak-password') {
          customMessage = 'Your password is too weak. Please use a stronger password'
        } else if (code === 'auth/user-disabled') {
          customMessage = `Your user has been disabled. Please contact your WIOCC agent.`
        }

        setErrorMessage(customMessage || message)
      })

      setLoading(false)
  }

  return (
    <div className={classes.root}>
      {brand.navbar.show && <Navbar />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Typography align="center" component="div">
            <Box className={classes.error} bgcolor='#E4E9ED' fontSize="fontSize" m={1}>
              {errorMessage}
            </Box>
          </Typography>
          <Typography component="div">
            <Box className={classes.success} bgcolor='#E4E9ED' fontSize="fontSize" m={1}>
              {successMessage}
            </Box>
          </Typography>
          <form action="/" method="post" className={classes.form}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                autoComplete="on"
                disabled={resetSuccessful}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={handleClickShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                id="password"
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                style={{background: '#e8f0fe'}}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </form>
          {
            resetSuccessful ? (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={loading}
                href="/"
                size='small'
              >
                Go to sign in
              </Button>
            ) : (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={loading}
                onClick={handleResetPassword}
                size='small'
              >
                Submit
              </Button>
            )
          }
          {loading && <CircularProgress size={24}  />}
        </div>
      </Container>
    </div>
  );
}