import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { createTheme, MuiThemeProvider, Typography } from '@material-ui/core';
import Utils from '../utils/Utils';
import MUIDataTable from 'mui-datatables';

const TAX_RATE = 0.07;

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    notFeasible: {
        textAlign: 'center',
        marginTop: '20px'
    }
});

const getMuiTheme = () =>
    createTheme({
        palette: {
            primary: {
                main: '#0e2c45',
            },
            secondary: {
                main: '#147d80',
            },
        },
        overrides: {
            MuiPaper: {
                root: {
                    padding: '0pt',
                    textAlign: 'center',
                    // height: '70vh',
                },
            },
            MuiToolbar: {
                root: {
                    minHeight: '20px !important',
                    maxHeight: '64px !important',
                    overflow: 'hidden',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    paddingLeft: '0pt !important',
                },
            },
            MuiIconButton: {
                root: {
                    padding: '0pt !important',
                    marginLeft: '10pt !important',
                },
            },
            MuiTablePagination: {
                root: {
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                },
            },
            MUIDataTableToolbar: {
                left: {
                    textAlign: 'left',
                },
            },
            MUIDataTable: {
                responsiveScrollMaxHeight: {
                    maxHeight: '100% !important',
                },
            },
            MuiCircularProgress: {
                root: {
                    marginTop: '0pt',
                    position: 'absolute',
                    top: '40vh',
                    zIndex: '100',
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    fontWeight: 'inherit'
                }
            }
        },
    })
    ;


function ccyFormat(num) {
    return `${Utils.formatZAF(num)}`;
}


export default function SpanningDataTable(props) {
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])

    const [options, setOptions] = useState({
        responsive: 'standard',
        rowsPerPage: rowsPerPage,
        sort: false,
        sortFilterList: false,
        filter: false,
        search: false,
        selectToolbarPlacement: 'none',
        selectableRows: 'none',
        elevation: 0,
        textLabels: {
            body: {
                noMatch: 'Not Feasible',
            },
        },
        viewColumns: false,
        downloadOptions: {
            filename: `QUOTE-${Utils.getCurrentDate()}.csv`,
            separator: ',',
        },
        customFooter: function (count, page, rowsPerPage) {
            return <></>
        },
        setRowProps: row => {
            if (!props.totals) return {
                style: {
                    fontWeight: 400,
                },
            };
            let pricingVals = row.filter(item => props.totals.filter(p => p.title === item || p.value === item).length > 0)
            if (pricingVals.length >= props.totals.length)
                return {
                    style: {
                        fontWeight: 'bold'
                    },
                };
            else return {
                style: {
                    fontWeight: 400,
                },
            };

        }
    });

    useEffect(() => {
        let numCols = 0
        if (props.rows) {
            let valsCols = []
            if (!props.rows) return
            props.columns.forEach(c => {
                valsCols.push(c.title)
            })
            setColumns(valsCols)
            numCols = valsCols.length
        }

        if (props.rows) {
            let valsData = []
            props.rows.forEach(row => {
                let val = []
                props.columns.forEach(c => {
                    val.push(row[c.value])
                })
                valsData.push(val)
            });

            if (props.totals && valsData && valsData.length > 0) {
                props.totals.forEach(row => {
                    let val = Array(numCols - props.totals.length).fill(null)
                    val.push(row.title)
                    val.push(row.value)
                    valsData.push(val)
                })
            }
            setData(valsData)
        }

        if (props.message) {
            let tmps = { ...options }
            tmps.textLabels.body.noMatch = props.message || 'Not Feasible'
            setOptions(tmps)
        }
    }, [props.rows])

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    theme={getMuiTheme}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </>

    );
}
