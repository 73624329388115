import React, { useContext, useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Navbar from '../components/Navbar';
import { BrandContext } from '../context/BrandContext/context'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useUserAuth } from '../context/AuthContext/AuthContext';
import firebase from 'firebase/app';
import 'firebase/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundImage: 'url(./logoBackground.jpg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "60%",
    backgroundColor: '#ffffff'
  },
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  error: {
    color: theme.palette.error.light,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  input: {
    background: '#e8f0fe'
  }
}));

const providers = {
  emailProvider: new firebase.auth.EmailAuthProvider(),
  microsoftProvider: new firebase.auth.OAuthProvider('microsoft.com')
};
if (providers.microsoftProvider) {
  providers.microsoftProvider.setCustomParameters({
    tenant: process.env.REACT_APP_TENANT_ID,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET
  })
}

export default function LoginView(props) {
  const classes = useStyles();

  const { brand } = useContext(BrandContext)
  const {onLoginMicrosoft, onLoginEmailPassword} = useUserAuth()
  
  const [state, setState] = React.useState({
    email: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const handleLogin = async () => {
    if (!brand.authentication) return;
    setLoading(true);
    setMessage('');  

    await onLoginEmailPassword(state.email, state.password)
    .catch(function (error) {

      let message = error.message;
      if (
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/wrong-password'
      )
        message = 'Invalid email or password provided.';

        setMessage(message);
    })
      
    setLoading(false);
  }

  const handleLoginWithProvider = async () => {
    if (!brand.authentication) return
    setLoading(true)
    setMessage('');

    await onLoginMicrosoft(providers.microsoftProvider)
    .catch((error) => {
      console.error('error', error)
      let message = error.message;
      if (
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/wrong-password'
      )
      message = 'Invalid email or password provided.';

      setMessage(message)
    });
    
    setLoading(false)
  }
  
  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <div className={classes.root}>
      {brand.navbar.show && <Navbar />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Typography align='center' component="div">
            <Box className={classes.error} bgcolor='#E4E9ED' fontSize="fontSize" m={1}>
              {message}
            </Box>
          </Typography>
          <form className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChange('email')}
              autoFocus
              disabled={loading}
              InputProps={{
                style: {
                  background: '#e8f0fe'
                }
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange('password')}
              disabled={loading}
              InputProps={{
                style: {
                  background: '#e8f0fe'
                }
              }}
            />
          </form>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            disabled={loading}
            onClick={handleLogin}
          >
            Sign In
          </Button>
          {onLoginMicrosoft &&
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
              onClick={handleLoginWithProvider}
            >
              Sign In With Microsoft
          </Button>
          }
          <Button
            type="submit"
            color=""
            className={classes.submit}
            disabled={loading}
            href='/forgot-password'
            size='small'
          >
            Forgot Password
          </Button>
          {loading && <CircularProgress size={24}  />}
        </div>
      </Container>
    </div>
  );
}