import React, { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'


function DeletePopup({onCancelChanges, onConfirmChanges, editingUserDetails}) {
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = (event) => {
    setIsLoading(true)
    onConfirmChanges(editingUserDetails.uid)
  }

  return (
    <Grid>
      <DialogTitle id="form-dialog-title">Delete User</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete user?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancelChanges}>
          Cancel
        </Button>
        <LoadingButton loading={isLoading} onClick={handleDelete}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Grid>
  )
}

export default DeletePopup
