import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
    LocationOn,
    MyLocation,
    Search,
    LocationSearching,
    LocationDisabled,
} from '@material-ui/icons';
import { Grid, TextField, Typography } from '@material-ui/core';
import { throttle } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        width: '100%',
        maxWidth: '500px'
    },
    title: {
        paddingLeft: '10px',
        paddingTop: '5px'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function WIOCCAutocompleteWidget({onAddressChange, id, title, nodes = [''], selectedNode }) {
    const classes = useStyles();
    
    // useEffect(() => {
    //   console.log('initial | address',displayAddress)
    //   console.log('initial | nodes',displayAddress)
    // },[])

    // useEffect(() => {
    //   console.log('selected node',displayAddress)
    // },[displayAddress])

    // useEffect(() => {
    //   console.log('nodes',nodes)
    // },[nodes])

    return (
        <Paper component="form" className={classes.root}>
            {title &&
                <div className={classes.title}>
                    {title}
                </div>
            }
            <div className={classes.content}>

              <Autocomplete
                id={id ? id : 'pac-input'}
                className={classes.input}
                getOptionLabel={(option) => option?.name || ''}
                freeSolo
                options={nodes || []}
                autoComplete
                value={selectedNode || {name: ''}}
                // value={displayAddress}
                // getOptionSelected={(option, value) => option.name === value.name}
                onChange={(event, newValue) => onAddressChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={'Select a pop'}
                    fullWidth
                  />
                )}
                renderOption={(option) => {

                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOn className={classes.icon} />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" color="textSecondary">
                          {option.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </div>
        </Paper>

    );
}
