import React, { useContext, useState } from "react";

import { Avatar, Box, Button, CircularProgress, Container, CssBaseline, TextField, Typography, makeStyles } from "@material-ui/core";
import { BrandContext } from "../context/BrandContext/context";
import { useUserAuth } from "../context/AuthContext/AuthContext";
import Navbar from "../components/Navbar";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundImage: 'url(./logoBackground.jpg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "60%",
    backgroundColor: '#ffffff'
  },
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  input: {
    background: '#e8f0fe'
  }
}));

export default function ForgotPasswordView() {
  const classes = useStyles();

  const { brand } = useContext(BrandContext)
  const { resetPasswordLink } = useUserAuth()

  const [state, setState] = React.useState({
    email: '',
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleForgotPassword = async () => {

    setLoading(true)

    await resetPasswordLink(state.email)
    .then(() => {
      setLoading(false)

      setSuccessMessage('Your reset password email has been sent.')
      setErrorMessage('')
    })
    .catch(() => {
      setLoading(false)

      setErrorMessage('Your reset password email failed to be sent, ensure that the email entered is a valid email.')
      setSuccessMessage('')
    })
  }

  return (
    <div className={classes.root}>
      {brand.navbar.show && <Navbar />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
                </Typography>
          <Typography align="center" component="div">
            <Box className={classes.error} bgcolor='#E4E9ED' fontSize="fontSize" m={1}>
              {errorMessage}
            </Box>
          </Typography>
          <Typography component="div">
            <Box className={classes.success} bgcolor='#E4E9ED' fontSize="fontSize" m={1}>
              {successMessage}
            </Box>
          </Typography>
          <form action="/" method="post" className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChange('email')}
              autoFocus
              disabled={loading}
              InputProps={{
                style: {
                  background: '#e8f0fe'
                }
              }}
            />
          </form>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submit}
            disabled={loading}
            onClick={handleForgotPassword}
            size='small'
          >
            Submit
          </Button>
          {loading && <CircularProgress size={24}  />}
        </div>
      </Container>
    </div>
  );
}