import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMap from './GoogleMap';
import { BrandContext } from '../context/BrandContext/context';
import GoogleSearchBox from './GoogleSearchBox';
import GoogleAutocompleteWidget from './GoogleAutocompleteWidget';
import OverlayLayerSelector from './OverlayLayerSelector';
import WMSOverlay from '../services/WMSOverlay'
import Utils from '../utils/Utils';
import Geolocation from '../services/Geolocation';
import LayerProviderListContent from './MarkerContent/LayerProviderListContent';
import TowerContent from './MarkerContent/TowerContent';
import { SiteContext } from '../context/SiteContext/context';
import fetchJsonp from 'fetch-jsonp';
import DrawLine from '../services/DrawLine';
import { InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { DataContext } from '../context/DataContext/context';
import LayerListContent from './MarkerContent/LayerListContent';
import PoPContent from './MarkerContent/PoPContent';
import { result } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
// require('es6-promise').polyfill();
import firebase from 'firebase'
import WIOCCService from '../services/APIs/WIOCCService';
import WIOCCAutocompleteWidget from './WIOCCAutocompleteWidget';

let GoogleDefaultInfoWindow = require('../utils/GoogleDefaultInfoWindow');
const ReactDOMServer = require('react-dom/server');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    rootNav: {
        display: 'flex',
        height: '100%'
    },
    googleMaps: {
        position: 'absolute'
    },
    userControls: {
        position: 'absolute',
        zIndex: '1000',
        width: '100%',
        maxWidth: '500px'
    },
    layerControls: {
        width: '100%',
        maxWidth: '400px',
        paddingTop: '2pt'
    },
    autocompleteContainer: {
        width: '100%',
        display: 'flex',
        marginBottom: '10px'
    },
    heightContainer: {
        maxWidth: '100px',
        paddingTop: '4pt',
        paddingLeft: '4pt'
    },
    numberSpiner: {
        margin: 0,
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    },
    inputFields: {
        display: 'flex'
    },
    dropdownsContainer: {
        width: '100%',
        margin: '1pt'
    },
    dropdowns: {
        width: '100%',
        padding: '1pt'
    },
    coverageMap: {
        top: 'calc(50%)',
        left: 'calc(50% - 20px)',
        position: 'absolute',
        zIndex: 9999,
    }
}));

export default function CoverageMapComponent(props) {

    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const { data, dispatchData } = useContext(DataContext)
    const [ bandwidth, setBandwidth ]  = useState(1000)
    const [ term, setTerm ] = useState(24)
    const classes = useStyles();
    // React.useLayoutEffect = React.useEffect
    const [disableGeolocation, setDisableGeolocation] = useState(false);
    const [searchingLocation, setSearchingLocation] = useState(true);
    const markerRef = useRef({}); //useState nod updating as component is not rendering at this stage
    const mapRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const googleGeocoderRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const popupRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const towerPopupRef = useRef(); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const coverageResultsRef = useRef({}); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const googlePlaceRef = useRef({}); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const googlePolylinesRef = useRef({}); //use Refe because of useState only updates the state after render, map not updating as the map does not rerender
    const [singleLayerSelect, setSingleSelect] = useState(false)
    // const [place, setPlace] = React.useState({});

    const [isLoading, setIsLoading] = useState(false)
    const locationRadiusRef = useRef()
    const [currentDisplayAddressA, setCurrentDisplayAddressA] = useState()
    const [currentDisplayAddressB, setCurrentDisplayAddressB] = useState(null)
    const [terms, setTerms] = useState([])
    const [bandwidths, setBandwidths] = useState([])

    const termRef = useRef(24)
    const bandwidthRef = useRef(1000)

    const [aggrigationNodes, setAggrigationNodes] = useState({
        'ZA': [],
        'NG': []
    })

    const [currentRegion, setCurrentRegion] = useState('ZA')

    const [googleMapOptions, setGoogleMapOptions] = React.useState({
        map: {
            center: brand.googlemaps.center ? brand.googlemaps.center : { lat: -26, lng: 25 },
            zoom: 6,
            minZoom: 3,
            maxZoom: 20,
            scaleControl: false,
            clickableIcons: false,
            mapTypeControl: true,
            zoomControl: true,
            fullscreenControl: false,
            streetViewControl: false,
            tilt: 0,
            rotateControl: false,
            styles: brand.googlemaps.mapstyle,
        },
        autocomplete: {
            componentRestrictions: {
                country: ['ZA', 'NGA']
            },
            fields: ["address_components", "formatted_address", "geometry"]
        }
    });

    useEffect(() => {
        bandwidthRef.current = bandwidth
        termRef.current = term

        if (!markerRef ||
            !markerRef.current['siteA'] ||
            !markerRef.current['siteA'].position) return  

        doCoverageCheck()

    },[term, bandwidth])

    useEffect(() => {

        if(!currentRegion) return

        setIsLoading(true)

        WIOCCService.getAggrigationNodes({region: currentRegion})
            .then((data) => {

                if(!data.error) setAggrigationNodes({
                    ...aggrigationNodes,
                    [currentRegion] : data
                })
            })
            
        WIOCCService.getSelections({region: currentRegion})
            .then((data) => {
                if(!data.error) {
                    setTerms(data.terms)
                    setBandwidths(data.bandwidths)
                }

                setIsLoading(false)
            })

    },[currentRegion])

    const handleMapLoad = (newMap) => {
        if (!mapRef || !mapRef.current) mapRef.current = newMap;
        // this.handleDataLayers();
        if (props.handleMapLoad) props.handleMapLoad();
        // addMapClickListener();
        let tmpOptions = googleMapOptions;
        tmpOptions.mapTypeControlOptions = {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            mapTypeIds: [
                window.google.maps.MapTypeId.ROADMAP,
                window.google.maps.MapTypeId.HYBRID,
                window.google.maps.MapTypeId.TERRAIN,
                window.google.maps.MapTypeId.SATELLITE
            ]
        }
        tmpOptions.zoomControlOptions = {
            position: window.google.maps.ControlPosition.RIGHT_CENTER
        }
        setGoogleMapOptions(tmpOptions);

        geolocate()

        let defaultLayers = data.layers.filter(l => l.active)

        addWMSOverlay(defaultLayers)
        // InitialiseGoogleAutocomplete()
    }

    const geolocate = (name) => {
        Geolocation.getUserLocation((pos) => {
            getRegion(pos.lat, pos.lng)
        }, (err) => {
            setDisableGeolocation(true);
            console.error('Geolocation Error: ', err)
            setCurrentRegion('ZA')
        });
    }

    const getRegion = (latitude, longitude) => {
        if (!window.google) throw "Google Maps not initialised";
        if (!googleGeocoderRef || !googleGeocoderRef.current) googleGeocoderRef.current = new window.google.maps.Geocoder();

        let nodeRegion

        googleGeocoderRef.current.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === "OK") {
                if (results[0]) { // take the first result
                    results[0].geometry.location = {
                        lat: () => { return latitude },
                        lng: () => { return longitude }
                    }

                    results[0].address_components.forEach(({types, short_name})=> {
                        if(types.includes('country')) {
                            nodeRegion = short_name
                        }
                    })
                    
                } else {
                    window.alert("No results found");
                }
            } else {
                console.error("Geocoder failed due to: " + status);
            }
        }).then(() => {
            setCurrentRegion(nodeRegion)
        });
    }

    useEffect(() => {
        assignLayerIndexes();
    }, [])

    const setLayers = layers => {
        let newData = data
        newData.layers = layers
        dispatchData(newData)
    }

    const assignLayerIndexes = () => {
        let allLayers = data.layers;
        for (let i = 0; i < allLayers.length; i++) {
            allLayers[i].layer_index = i;
        }
        setLayers(allLayers)
    }

    const reverseGeocoding = (latitude, longitude, name) => {
        if (!window.google) throw "Google Maps not initialised";
        if (!googleGeocoderRef || !googleGeocoderRef.current) googleGeocoderRef.current = new window.google.maps.Geocoder();
        googleGeocoderRef.current.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            setSearchingLocation(false);
            if (status === "OK") {
                if (results[0]) { // take the first result
                    results[0].geometry.location = {
                        lat: () => { return latitude },
                        lng: () => { return longitude }
                    }
                    googlePlaceRef.current[name] = results[0];

                    if (props.onAddressChange) props.onAddressChange(results[0])
                    if (props.onResultsReceived) props.onResultsReceived()
                    doCoverageCheck();
                } else {
                    window.alert("No results found");
                }
            } else {
                console.error("Geocoder failed due to: " + status);
            }
        });
    }

    const handleMarker = (pos, icon, name) => {
        if (!markerRef.current[name]) {
            markerRef.current[name] = new window.google.maps.Marker({
                position: pos,
                map: mapRef.current,
                optimized: false,
                draggable: name === 'siteA' ? true : false,
                icon,
            });
            if (!settings.isMobile && name === 'siteA') {
                markerRef.current[name].addListener('click', function () {
                    updatePopup(name);
                });
                markerRef.current[name].addListener('dragstart', function () {
                    // updatePopup()
                    // hidePopup();
                    // hideTowerPopup(name);
                    clearState().then(() => {
                        console.log('state cleared')
                    })
                });
                markerRef.current[name].addListener('dragend', function () {
                    // goToPosition(new window.google.maps.LatLng(markerRef.current[name].position.lat(), markerRef.current[name].position.lng()));
                    panToMarkers()
                    
                    reverseGeocoding(markerRef.current[name].position.lat(), markerRef.current[name].position.lng(), name)
                });
            }
        } else {
            markerRef.current[name].setPosition(pos)
        }

        panToMarkers()
        // goToPosition(pos)
        // handlePopup(pos, <DefaultMarkerContent layers={layers} />)
    }

    const hidePopup = () => {
        if (!popupRef || !popupRef.current) return;
        popupRef.current.setMap(null);
        popupRef.current.close()
    }

    const handlePopup = (position, content, name) => {
        if (props.hidepopup) return
        if (!mapRef || !mapRef.current) throw 'Google Map not initialised';
        if (!name) throw 'Marker name not specified'
        if (!position) {
            if (!markerRef || !!markerRef.current[name])
                throw 'No or invalid position provided.'
            position = { lat: markerRef.current[name].position.lat(), lng: markerRef.current[name].position.lng() }
        };

        if (!popupRef || !popupRef.current) {
            popupRef.current = new window.google.maps.InfoWindow({
                content: ReactDOMServer.renderToStaticMarkup(content),
            });
        }
        // popupRef.current.setPosition(position)
        else if (content) {
            popupRef.current.setContent(ReactDOMServer.renderToStaticMarkup(content));
            popupRef.current.setPosition(position)
        }
        // popupRef.current.setMap(mapRef.current);
        popupRef.current.open(mapRef.current, markerRef.current[name])

    }

    const hideTowerPopup = (name) => {
        if (!towerPopupRef || !towerPopupRef.current) return;
        towerPopupRef.current.setMap(null);
        towerPopupRef.current.close()
    }

    const clearState = async () => {
        
        if(props.onResultsReceived) props.onResultsReceived() // clear feasibility list
    
        if(googlePlaceRef.current['siteB']) googlePlaceRef.current['siteB'] = null

        if(markerRef.current['siteB']) {
            markerRef.current['siteB'].setMap(null)
            markerRef.current['siteB'] = null

            setCurrentDisplayAddressB(null)
        }
    }

    const handleTowerPopup = (position, content, towerMarker) => {
        if (props.hidepopup) return
        if (!mapRef || !mapRef.current) throw 'Google Map not initialised';
        if (!position) {
            if (!towerMarker)
                throw 'No or invalid position provided.'
            position = { lat: towerMarker.position.lat(), lng: towerMarker.position.lng() }
        };

        if (!towerPopupRef || !towerPopupRef.current) {
            towerPopupRef.current = new window.google.maps.InfoWindow({
                content: ReactDOMServer.renderToStaticMarkup(content),
            });
        }
        // popupRef.current.setPosition(position)
        else if (content) {
            towerPopupRef.current.setContent(ReactDOMServer.renderToStaticMarkup(content));
            towerPopupRef.current.setPosition(position)
        }
        // popupRef.current.setMap(mapRef.current);
        towerPopupRef.current.open(mapRef.current, towerMarker)

    }

    const updatePopup = (name) => {

        setCurrentDisplayAddressA(googlePlaceRef.current['siteA'] || {})
        setCurrentDisplayAddressB(googlePlaceRef.current['siteB'] || {})

        // if (!markerRef || !markerRef.current[name]) return;
        // handlePopup(markerRef.current.position, <LayerProviderListContent layers={data.layers} coverage={coverageResultsRef.current} place={googlePlaceRef.current} />)
        // if (!props.hidepopup)
        //     handlePopup(markerRef.current[name].position, <LayerListContent layers={data.layers} coverage={coverageResultsRef.current} place={googlePlaceRef.current[name]} />)
        //     hideLineLocation(data.layers, name)
        //     showLineLocation(data.layers.filter(l => l.active && l.showLocation), undefined, undefined, name)
        // let tmp = place
        // tmp[name] = googlePlaceRef.current[name]
        // setPlace(tmp)
        
    }

    const updatePoPPopup = (popMarker, details, name) => {
        if (!popMarker) return;
        let location = {
            latitude: markerRef.current[name].position.lat(),
            longitude: markerRef.current[name].position.lng()
        }
        handleTowerPopup(popMarker.position, <PoPContent details={details} location={location} />, popMarker)
    }

    //pan map to latlng
    const goToPosition = (pos) => {
        if (brand.googlemaps.markerZoomLevel) mapRef.current.setZoom(brand.googlemaps.markerZoomLevel);
        mapRef.current.panTo(pos);

        let topEdgeOffset;
        if (settings.pageHeight < 600) {
            topEdgeOffset = settings.pageHeight * 0.05;
        } else {
            topEdgeOffset = settings.pageHeight * 0.1;
        }
        if (settings.isMobile) {
            topEdgeOffset = settings.pageHeight * 0.25;
        }
        mapRef.current.panBy(0, -topEdgeOffset);
    }

    const panToMarkers = () => {
        if (!window.google.maps) return

        if (markerRef.current['siteA'] && markerRef.current['siteB']) {
            var bounds = new window.google.maps.LatLngBounds();
            bounds.extend(markerRef.current['siteA'].getPosition())
            bounds.extend(markerRef.current['siteB'].getPosition())
            mapRef.current.fitBounds(bounds);
        } else if (markerRef.current['siteA']) {
            goToPosition(new window.google.maps.LatLng(markerRef.current['siteA'].position.lat(), markerRef.current['siteA'].position.lng()));
        } else if (markerRef.current['siteB']) {
            goToPosition(new window.google.maps.LatLng(markerRef.current['siteB'].position.lat(), markerRef.current['siteB'].position.lng()));
        }
    }

    const onAddressChange = (place) => {
        if (typeof place === 'string') place = place.replace(/ +/g, " ")
        if (!googlePlaceRef.current) googlePlaceRef.current = {}

        if(props.onResultsReceived) props.onResultsReceived() //clear the result set if the address changed

        // hide site b

        if(googlePlaceRef.current['siteB']) googlePlaceRef.current['siteB'] = null
        
        if(markerRef.current['siteB']) {
            markerRef.current['siteB'].setMap(null)
            markerRef.current['siteB'] = null

            setCurrentDisplayAddressB(null)
        }

        // hide site a

        if(googlePlaceRef.current['siteA']) googlePlaceRef.current['siteA'] = null
        
        if(markerRef.current['siteA'] && !place) {
            markerRef.current['siteA'].setMap(null)
            markerRef.current['siteA'] = null

            setCurrentDisplayAddressA(null)
            return
        }

        googlePlaceRef.current['siteA'] = place;
        parseAddressChange(place, 'siteA')
    }

    const onAddressBChange = (node) => {

        if(props.onResultsReceived) props.onResultsReceived()
        
        if(node === null && !markerRef.current['siteB']) {
            return
        }

        if(googlePlaceRef.current['siteB']) googlePlaceRef.current['siteB'] = null
        
        if(markerRef.current['siteB'] && !node?.location) {
            markerRef.current['siteB'].setMap(null)
            markerRef.current['siteB'] = null

            setCurrentDisplayAddressB(null)

            return
        }
    
        if (!googlePlaceRef.current) googlePlaceRef.current = {}
        googlePlaceRef.current['siteB'] = node;
        parseNodeChange(node)
    }

    const parseAddressChange = (place, name) => {
        hideTowerPopup(name);
        if (props.onAddressChange) props.onAddressChange(place)
        let icon = {
            url: name === 'siteB' ? '/markerRedB.svg' : '/markerRedA.svg',
            scaledSize: new window.google.maps.Size(30, 37),
            size: new window.google.maps.Size(30, 37),
            anchor: new window.google.maps.Point(15, 37)
        }

        if (place && place.geometry) {
            getRegion(place.geometry.location.lat(), place.geometry.location.lng())
            // updateMarkerAndPopup(new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
            handleMarker(new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()), icon, name)
            // goToPosition(new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()));
            doCoverageCheck();
        } else {
            let coords = validateCoords(place)
            if (coords) {
                getRegion(+coords[0], +coords[1])
                handleMarker(new window.google.maps.LatLng(+coords[0], +coords[1]), icon, name)
                // goToPosition(new window.google.maps.LatLng(+coords[0], +coords[1]));
                // goToPosition()
                reverseGeocoding(+coords[0], +coords[1], name)
            }
            // doCoverageCheck(coords[0], coords[1]);
            // updateMarkerAndPopup(new window.google.maps.LatLng(coords[0], coords[1]))
        }

        panToMarkers()
    }

    const parseNodeChange = (node, coverageCheck = true) => {

        let icon = {
            url: '/markerRedB.svg',
            scaledSize: new window.google.maps.Size(30, 37),
            size: new window.google.maps.Size(30, 37),
            anchor: new window.google.maps.Point(15, 37)
        }

        handleMarker(new window.google.maps.LatLng(node.location.latitude, node.location.longitude), icon, 'siteB' )
        if(coverageCheck) doCoverageCheck()
        panToMarkers()
    }

    const doCoverageCheck = () => {
        if (googlePlaceRef.current['siteA']) updatePopup('siteA')
        if (googlePlaceRef.current['siteB']) updatePopup('siteB')

        if (!googlePlaceRef.current['siteA']) return

        if (!termRef.current && !bandwidthRef.current) return

        let sitea_latitude = googlePlaceRef.current['siteA'].geometry.location.lat(),
            sitea_longitude = googlePlaceRef.current['siteA'].geometry.location.lng(),
            siteb_latitude = googlePlaceRef?.current['siteB']?.location?.latitude || null,
            siteb_longitude = googlePlaceRef?.current['siteB']?.location?.longitude || null
        coverageResultsRef.current = {}

        doFeasibilityCheck(sitea_latitude, sitea_longitude, siteb_latitude, siteb_longitude, locationRadiusRef.current, bandwidthRef.current, termRef.current)

    }

    const doFeasibilityCheck = async (sitea_latitude, sitea_longitude, siteb_latitude, siteb_longitude, radius, bandwidthParam, termParam) => {
        setIsLoading(true)
        // console.log('coverage starting', coverageResultsRef.current)
        let userEmail = ''
        if (firebase.auth().currentUser && firebase.auth().currentUser.email) {
            userEmail = firebase.auth().currentUser.email
        }
        const coverageRequest = await fetch(`${process.env.REACT_APP_CUSTOM_API_URL}/getfeasibility?key=${brand.coverageAPI.apikey}&services=&sitea_latitude=${sitea_latitude}&sitea_longitude=${sitea_longitude}${siteb_latitude ? `&siteb_latitude=${siteb_latitude}` : ''}${siteb_longitude ? `&siteb_longitude=${siteb_longitude}`: ''}${radius ? '&radius=' + radius : ''}&bandwidth=${bandwidthParam}&term=${termParam}&channel=${process.env.REACT_APP_CHANNEL}-single-${userEmail}`);

        if (!coverageRequest.ok) {
            throw "Oops, something went wrong: " + coverageRequest.status
        }
        let results = await coverageRequest.json();

        // if (results) coverageResultsRef.current = Utils.deepMerge(coverageResultsRef.current, results)

        if(!googlePlaceRef.current['siteB'] && results?.siteB?.pop) {
            googlePlaceRef.current['siteB'] = results.siteB.pop

            parseNodeChange(results.siteB.pop, false)
        }

        coverageResultsRef.current = results
        updatePopup('siteA');
        updatePopup('siteB');

        if (props.onResultsReceived && coverageResultsRef.current) props.onResultsReceived(coverageResultsRef.current)
        setIsLoading(false)
    }

    const validateCoords = (value) => {
        if (!value) return false;
        let splitVal = value.split(',');
        if (!splitVal || splitVal.length < 2) splitVal = value.split(' ');
        if (!splitVal || splitVal.length < 2 || !splitVal[0] || !splitVal[1])
            return false;
        if (
            splitVal[0] > -90 &&
            splitVal[0] < 90 &&
            splitVal[1] > -180 &&
            splitVal[1] < 180
        )
            return splitVal;
        return false;
    }

    const handleLayerItemClick = (item) => {
        let newLayers = [...data.layers];
        hideLineLocation(newLayers.filter(l => !l.active))

        if (singleLayerSelect) {
            newLayers.forEach(layer => {
                layer.active = false;
                if (layer.layerName === item.layerName) layer.active = true;
            });
            if (item.showLocation) {
                showLineLocation([item], undefined, undefined, 'siteA')
                showLineLocation([item], undefined, undefined, 'siteB')
            }
            addWMSOverlay([item])
        } else {

            let selectedLayers = newLayers.filter(l => l.layerName === item.layerName)
            if (!selectedLayers || selectedLayers.length === 0) return;
            selectedLayers[0].active = !selectedLayers[0].active;

            // addWMSOverlay(newLayers.filter(l => l.active && !l.showLocation))
            addWMSOverlay(newLayers.filter(l => l.active))

            showLineLocation(newLayers.filter(l => l.active && l.showLocation), undefined, undefined, 'siteA')
            showLineLocation(newLayers.filter(l => l.active && l.showLocation), undefined, undefined, 'siteB')
        }
        hideWMSOverlay(newLayers.filter(l => !l.active))
        // hideLineLocation(newLayers.filter(l => !l.active))
        setLayers(newLayers);
    }

    const addWMSOverlay = (layers, layerIndex) => {
        let displayLayers = []
        let externalWMSLayers = []
        let maxLayerIndex = layerIndex ? layerIndex : 0;
        let maxExternalLayerIndex = layerIndex ? layerIndex : 0;
        layers.forEach(layer => {
            if (layer.externalWMS) {
                externalWMSLayers.push(layer);
                maxExternalLayerIndex = layer.layer_index > maxExternalLayerIndex ? layer.layer_index : maxExternalLayerIndex
            }
            else {
                displayLayers.push(layer);
                maxLayerIndex = layer.layer_index > maxLayerIndex ? layer.layer_index : maxLayerIndex
            }
        })
        if (brand.coverageAPI.useCustomAPI) {
            if (displayLayers.length > 0) addCustomWMSOverlay(displayLayers, layerIndex)// ? layerIndex : maxLayerIndex)
        } else {
            if (displayLayers.length > 0) add28EastWMSOverlay(displayLayers, layerIndex)// ? layerIndex : maxLayerIndex)
        }

        if (externalWMSLayers.length > 0) addExternalWMSOverlay(externalWMSLayers, layerIndex ? layerIndex : maxExternalLayerIndex)
    }

    const add28EastWMSOverlay = (layers, layerIndex = 0) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"

        let displayLayers = "", displayStyles = "";

        layers.forEach(layer => {
            if (!layer.externalWMS && !layer.noWMS) {
                if (layerIndex) {
                    displayLayers += "," + layer.layerName;
                    displayStyles += "," + layer.layerStyle;
                }
                mapRef.current.overlayMapTypes.setAt(layer.layer_index, WMSOverlay.GetGoogleMapTileOverlay(mapRef.current, brand.coverageAPI.apikey, layer.layerName, layer.layerStyle, { url: process.env.REACT_APP_28EAST_WMS_BASE_URL }));
            }
        });
        if (layerIndex) {
            displayLayers = displayLayers.substring(1, displayLayers.length)
            displayStyles = displayStyles.substring(1, displayStyles.length)
            mapRef.current.overlayMapTypes.setAt(layerIndex, WMSOverlay.GetGoogleMapTileOverlay(mapRef.current, brand.coverageAPI.apikey, displayLayers, displayStyles, { url: process.env.REACT_APP_28EAST_WMS_BASE_URL }));
        }
    }

    const addCustomWMSOverlay = (layers, layerIndex = 0) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"

        let displayLayers = "", displayStyles = "";
        layers.forEach(layer => {
            if (!layer.externalWMS && !layer.noWMS) {
                if (layerIndex) {
                    displayLayers += "," + layer.layerName;
                    displayStyles += "," + layer.layerStyle;
                }
                mapRef.current.overlayMapTypes.setAt(layer.layer_index, WMSOverlay.GetGoogleMapTileOverlay(mapRef.current, brand.coverageAPI.apikey, layer.layerName, layer.layerStyle, { url: process.env.REACT_APP_CUSTOM_WMS_BASE_URL }));
            }
        });
        if (layerIndex) {
            displayLayers = displayLayers.substring(1, displayLayers.length)
            displayStyles = displayStyles.substring(1, displayStyles.length)
            mapRef.current.overlayMapTypes.setAt(layerIndex, WMSOverlay.GetGoogleMapTileOverlay(mapRef.current, brand.coverageAPI.apikey, displayLayers, displayStyles, { url: process.env.REACT_APP_CUSTOM_WMS_BASE_URL }));
        }
    }

    const addExternalWMSOverlay = (layers, layerIndex = 0) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"

        layers.forEach(layer => {
            if (layer.externalWMS)
                mapRef.current.overlayMapTypes.setAt(layerIndex ? layerIndex : layer.layer_index, WMSOverlay.GetGoogleMapWMSTileOverlay(mapRef.current, layer.externalWMSURL, null, layer.layerName, layer.layerStyle, layer.customSLD, { bboxm: 'meters' }));
        })
    }

    const hideWMSOverlay = (layers, layerIndex = 0) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"

        layers.forEach(layer => {
            mapRef.current.overlayMapTypes.setAt(layerIndex ? layerIndex : layer.layer_index, null);
        })
    }

    const showLineLocation = (layers, latitude, longitude, name) => {
        if (!layers) return;
        if (!coverageResultsRef.current) return

        if (!latitude || !longitude) {
            if (!markerRef.current[name]) return

            latitude = markerRef.current[name].position.lat()
            longitude = markerRef.current[name].position.lng()
        }

        if (!googlePolylinesRef.current[name]) googlePolylinesRef.current[name] = []

        layers.forEach(layer => {
            if (layer.showLocation) {
                if (coverageResultsRef.current[name] && coverageResultsRef.current[name].pop && coverageResultsRef.current[name].pop.location) {
                    let pop = coverageResultsRef.current[name].pop
                    if (!pop.attributes) pop.attributes = {}
                    if (!pop.attributes.provider) pop.attributes.provider = pop.name
                    let options = { icon: name === 'siteB' ? '/popB.svg' : '/pop.svg', name: name }
                    googlePolylinesRef.current[name].push(DrawLine.drawLine(mapRef.current, new window.google.maps.LatLng(latitude, longitude), new window.google.maps.LatLng(pop.location.lat, pop.location.lng), layer.layerCoverageName + '-icon', options, updatePoPPopup, pop))
                }
            }
        })

    }

    const hideLineLocation = (layers, name) => {
        if (!mapRef || !mapRef.current) throw "Map not initialised";
        if (!layers) throw "Invalid layers defined"
        if (!googlePolylinesRef.current[name] || googlePolylinesRef.current[name].length === 0) return

        googlePolylinesRef.current[name] = DrawLine.clearLines(googlePolylinesRef.current[name]);
    }

    return (
        <div className={classes.root}>

            <div className={classes.coverageMap}>
                {(isLoading) && <CircularProgress size={50} />}
            </div>

            <div className={classes.userControls}>
                <div className={classes.autocompleteContainer}>
                    <GoogleAutocompleteWidget
                        noLoad={true}
                        id='pac-input'
                        title='Site A'
                        placeholder='Input GPS coordinates or physical address'
                        disableGeolocation={disableGeolocation}
                        handleAddressChange={onAddressChange}
                        autocomplete={googleMapOptions.autocomplete}
                        // handleGeolocate={geolocate}
                        // loadingLocation={searchingLocation}
                        place={googlePlaceRef.current['siteA']}
                        hideLocationIcon={true}
                        displayAddress={currentDisplayAddressA}
                    />
                </div>
                <div className={classes.autocompleteContainer}>
                    <WIOCCAutocompleteWidget
                        id='pac-input'
                        title='Select an aggregation node'
                        onAddressChange={onAddressBChange}
                        nodes={aggrigationNodes[currentRegion]}
                        selectedNode={currentDisplayAddressB}
                    />
                </div>
                <div className={classes.inputFields}>
                    <Paper
                        className={classes.dropdownsContainer}>
                        {/* <InputLabel id="bandwidth-label">Bandwidth</InputLabel> */}
                        <Select
                            className={classes.dropdowns}
                            labelId="bandwidth"
                            id="bandwidth"
                            value={bandwidth}
                            onChange={(event) => setBandwidth(event.target.value)}
                        >
                            {
                                bandwidths.map(({label, value}) => (
                                    <MenuItem key={value} value={value}>{label}</MenuItem>
                                ))
                            }
                        </Select>
                    </Paper>
                    <Paper
                        className={classes.dropdownsContainer}>
                        {/* <InputLabel id="term-label">Term</InputLabel> */}
                        <Select
                            className={classes.dropdowns}
                            labelId="term"
                            id="term"
                            value={term}
                            onChange={(event) => setTerm(event.target.value)}
                        >
                            {
                                terms.map(({label, value}) => (
                                    <MenuItem key={value} value={value}>{label}</MenuItem>
                                ))
                            }
                        </Select>
                    </Paper>
                </div>
                {!props.hidelayercontrol &&
                    <div className={classes.layerControls}>
                        <OverlayLayerSelector
                            key={'overlay-selector'}
                            toggleTitle={'Filter coverage layer'}
                            // displayToggle={false}
                            layers={data.layers}
                            handleLayerItemClick={handleLayerItemClick}
                            singleSelect={singleLayerSelect}
                            displayToggle
                            layerLegend
                            useSubheadings={brand.layerSelection.useSubheadings}
                        />
                    </div>
                }
            </div>
            <GoogleMap
                className={classes.googleMaps}
                apikey={brand.googlemaps.apikey}
                options={googleMapOptions}
                // handleMapClick={handleMapClick}
                onMapLoad={handleMapLoad}
                autocompleteInput={'pac-input'}
                onAddressChange={onAddressChange}
            // addMapClickListener={!settings.isMobile}
            />

        </div>
    );
}
