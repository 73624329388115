import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { BrandContext } from '../context/BrandContext/context';
import Utils from '../utils/Utils';
import MUIDataTable from 'mui-datatables';
import { CircularProgress } from '@material-ui/core';
import SpanningTable from './SpanningTable';
import SpanningDataTable from './SpanningDataTable';
// require('es6-promise').polyfill();

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
    },
}));
const getMuiTheme = () =>
    createTheme({
        palette: {
            primary: {
                main: '#0e2c45',
            },
            secondary: {
                main: '#147d80',
            },
        },
        overrides: {
            MuiPaper: {
                root: {
                    padding: '0pt',
                    textAlign: 'center',
                    // height: '70vh',
                },
            },
            MuiToolbar: {
                root: {
                    minHeight: '20px !important',
                    maxHeight: '64px !important',
                    overflow: 'hidden',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    paddingLeft: '0pt !important',
                },
            },
            MuiIconButton: {
                root: {
                    padding: '0pt !important',
                    marginLeft: '10pt !important',
                },
            },
            MuiTablePagination: {
                root: {
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                },
            },
            MUIDataTableToolbar: {
                left: {
                    textAlign: 'left',
                },
            },
            MUIDataTable: {
                responsiveScrollMaxHeight: {
                    maxHeight: '100% !important',
                },
            },
            MuiCircularProgress: {
                root: {
                    marginTop: '0pt',
                    position: 'absolute',
                    top: '40vh',
                    zIndex: '100',
                },
            },
        },
    })
;

export default function FeasibilityViewTotals(props) {
    const { brand } = useContext(BrandContext)
    const classes = useStyles()

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [downloadFile, setDownloadFile] = useState(true)

    const options = {
        responsive: 'standard',
        rowsPerPage: rowsPerPage,
        sort: false,
        sortFilterList: false,
        filter: false,
        search: false,
        selectToolbarPlacement: 'none',
        selectableRows: 'none',
        elevation: 0,
        textLabels: {
            body: {
                noMatch: 'Not Feasible',
            },
        },
        viewColumns: false,
        downloadOptions: {
            filename: `AvailableCoverage-${Utils.getCurrentDate()}.csv`,
            separator: ',',
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            if (props.formattedAddress) {
                // console.log(data);
                data.push({
                    index: data.length,
                    data: ['address', props.formattedAddress],
                });
                // console.log(data);

                return `${buildHead(columns)}${buildBody(data)}`;
            } else {
                return `${buildHead(columns)}${buildBody(data)}`;
            }
        },
    };

    return (
        <div className={classes.root}>

            {!props.loading ? (
                <MuiThemeProvider theme={getMuiTheme()}>
                    <SpanningDataTable
                        theme={getMuiTheme}
                        title={'Feasibility Check'}
                        columns={props.columns}
                        rows={props.rows}
                        totals={props.totals}
                        message={props.message}
                    />
                </MuiThemeProvider>
            ) : (

                    <MuiThemeProvider theme={getMuiTheme()}>

                        <CircularProgress className={classes.spinner} />

                    </MuiThemeProvider>
                )}
        </div>
    );
}
