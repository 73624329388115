const DrawLine = {}

DrawLine.drawLine = (googleMaps, pointA, pointB, identifier,options, markerClickHandler, details) => {
    if(!window.google) throw "Google Maps Not initialised";
    if(!googleMaps) throw "Google Maps Reference object required"
    if(!pointA) throw "Invalid PointA (start position)"
    if(!pointB) throw "Invalid PointB (end position)"

    let newOptions = {
        lineColor: '#000000',
        lineOpacity: 1.0,
        lineWeight: 2,
        icon: '/pop.png',
        ...options
    }

    let path = [pointA, pointB];
    let linePath = new window.google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: newOptions.lineColor,
      strokeOpacity: newOptions.lineOpacity,
      strokeWeight: newOptions.lineWeight,
    });

    let marker = new window.google.maps.Marker({
        position: pointB,
        map: googleMaps,
        optimized: false,
        draggable: false,
        icon: {
            url: newOptions.icon,
            scaledSize: new window.google.maps.Size(25, 25),
            size: new window.google.maps.Size(25, 25),
            anchor: new window.google.maps.Point(12, 17)
        },
    });
    if(markerClickHandler){
        marker.addListener('click', function () {
            markerClickHandler(marker, details, newOptions.name );
        });
    }
    linePath.setMap(googleMaps);
    return { identifier: identifier, path: linePath, marker: marker };
  }

DrawLine.clearLines = (polylines) => {
    if (polylines && polylines.length > 0) {
        for (let i = 0; i < polylines.length; i++) {
            if (polylines[i] && polylines[i].path) {
                polylines[i].path.setMap(null);
                polylines[i].marker.setMap(null);
            }
        }
        polylines = [];
    }
    return polylines;
}

export default DrawLine