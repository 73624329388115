import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import Utils from '../../utils/Utils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '250px',
    },
    contentRoot: {
        width: '100%',
    },
}));

function Content(props) {
    const classes = useStyles();

    return (
        <div className={classes.contentRoot}>
            <Typography variant='h6'>
                {props.details && props.details.tower && props.details.tower.name ? props.details.tower.name: 'No Tower Data to display'}
            </Typography>
            <Typography variant='body1'>
                Tower Provider: {props.details && props.details.tower && props.details.tower.attributes && props.details.tower.attributes.provider ? props.details.tower.attributes.provider: '-'}
            </Typography>
            <Divider></Divider>
            <Typography variant='body1'>
                Tower Height: {props.details && props.details.tower && props.details.tower.height ? props.details.tower.height: 0}m
            </Typography>
            <Divider></Divider>
            <Typography variant='body1'>
                Distance: {props.details && props.details.distance ? props.details.distance : 0}m
            </Typography>
            <Divider></Divider>
            <Typography variant='body1'>
                Client To Tower: {props.clientDegrees ? props.clientDegrees : 0}&deg;
            </Typography>
            <Divider></Divider>
            <Typography variant='body1'>
                Tower To Client: {props.degrees ? props.degrees : 0}&deg;
            </Typography>
            <Divider></Divider>
        </div>
    );
}


export default function TowerContent(props) {
    const classes = useStyles();
    let local1 = {
        latitude: props.location.latitude,
        longitude: props.location.longitude
    }
    let local2 = {
        latitude: props.details.tower.location.latitude,
        longitude: props.details.tower.location.longitude
    }
    const clientDegrees = Utils.angleFromCoords(local1.latitude, local2.latitude, local1.longitude, local2.longitude)
    const degrees = Utils.angleFromCoords(local2.latitude, local1.latitude, local2.longitude, local1.longitude)
    return (
        <div className={classes.root}>
            <Content details={props.details} clientDegrees={clientDegrees} degrees={degrees}/>
        </div>
    );
}