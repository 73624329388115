import axios from "axios"
import firebase from "firebase"

const Logging = {}

Logging.auditLog = async (request, response) => {
  if (!request) return
  let cleanResponse = {results: []}

  if(!response.results) 
    cleanResponse = {results: [...response.results]}


  const usrRequest = JSON.parse(JSON.stringify(request))
  let customMetadata

  if (request.metadata) {
    customMetadata = JSON.parse(JSON.stringify(request.metadata))
    delete request.metadata
  }

  let logName = process.env.REACT_APP_AUDIT_LOGGING_API_LOG_NAME ? process.env.REACT_APP_AUDIT_LOGGING_API_LOG_NAME : ''

  const logData = {
    log_name:
      request.log_name ?? logName,
    user: firebase.auth().currentUser ? firebase.auth().currentUser.email : '',
    action: request.action ?? 'single-feasibility',
    metadata: {
      request: { ...usrRequest },
      response: { ...response }
    },
  }

  if (cleanResponse?.results?.length) {
    if (Array.isArray(cleanResponse.results) && !cleanResponse.results.length) {
      cleanResponse.results = []
    } else if (Array.isArray(cleanResponse.results) && cleanResponse.results[0]?.Feasible === 'Not Feasible') {
      cleanResponse.results = []
    }
    logData.metadata.response = { ...cleanResponse }
  }

  if (customMetadata)
    logData.metadata = { ...logData.metadata, ...customMetadata }

  try {
    const logResponse = axios.post(
      `${process.env.REACT_APP_AUDIT_LOGGING_API_URL}audit/logs?key=${process.env.REACT_APP_AUDIT_LOGGING_API_KEY}`,
      logData,
    )
    const logResponseJson = logResponse

    if (logResponseJson.status === 200){
      // eslint-disable-next-line consistent-return
      return {
        success: true,
        code: 200,
        message: 'Ok',
      }
    }

      // eslint-disable-next-line consistent-return
    return {
        success: false,
        code: logResponseJson.status,
        message: 'Oops something went wrong',
      }

  } catch (e) {
    console.error('Audit Logging error: ', e.message)

      // eslint-disable-next-line consistent-return
    return {
      success: false,
      code: 500,
      message: 'Oops something went wrong',
    }
  }
}

export default Logging
