import React, { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import { useUserAuth } from '../../context/AuthContext/AuthContext'
import Utils from '../../utils/Utils'
//import { useSettings } from '../../context/SettingsContext/SettingsContext'

const roleDict = [
  { name: 'user', fullName: 'User' },
  { name: 'admin', fullName: 'Admin' },
  { name: 'sysadmin', fullName: 'Sysadmin' },
]

const generateRoleSelectors = (userDetails, changeHandler, userClaims) => {
  const accessSelectorArray = roleDict.map(({ name, fullName }) => {
    if (name === 'user' || name === 'admin' || name === 'sysadmin') return null

    const label = { inputProps: { 'aria-label': fullName } }

    return (
      <FormControlLabel
        key={name}
        control={
          <Checkbox
            {...label}
            checked={userDetails?.[name] === true}
            disabled={name === 'user'}
            name={name}
            onChange={changeHandler}
          />
        }
        label={fullName}
      />
    )
  })

  const authSelectorArray = roleDict.map(({ name, fullName }) => {
    if (name === 'single' || name === 'bulk' || name === 'lineofsight') return null
    if (name === 'sysadmin' && !Utils.isSysAdmin(userClaims)) return null

    const label = { inputProps: { 'aria-label': fullName } }

    return (
      <FormControlLabel
        key={name}
        control={
          <Checkbox
            {...label}
            checked={userDetails?.[name] === true}
            disabled={name === 'user'}
            name={name}
            onChange={changeHandler}
          />
        }
        label={fullName}
      />
    )
  })

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
        <FormLabel component="legend">Assign responsibility</FormLabel>
        <FormGroup>{authSelectorArray}</FormGroup>
      </FormControl>

    </Box>
  )
}

const accessibleTools = (roleAccess) => {
  const tools = []
  
  for (const [key, value] of Object.entries(roleAccess)) {
    if(value === true) {
      tools.push(key)
    }
  }

  return tools
}

function EditPopup({ editingUserDetails, onCancelChanges, onConfirmChanges, edit }) {
  const { userClaims } = useUserAuth()
  const [isLoading, setIsLoading] = useState(false)
  // const {roleAccess} = useSettings()

  const [error, setError] = useState(!edit)

  const [userDetails, setUserDetails] = useState({
    uid: editingUserDetails.uid || '',
    displayName: editingUserDetails.displayName || '',
    email: editingUserDetails.email || '',
    roles: editingUserDetails.roles || {
      admin: false,
    }
  })

  const handleChange = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value
    })
  }

  const handleRoleChange = (event) => {
    // Auth level changes need to grant previous levels of auth as well.
    // This will check the auth level (index) and also activate everything below it
    const selectedRoleIndex = -1

    // if(event.target.checked && )


    setUserDetails({
      ...userDetails,
      roles: {
        ...userDetails.roles,
        [event.target.name]: event.target.checked
      }
    })
  }

  const handleConfirm = async () => {
    setIsLoading(true)

    if (edit) {
      await onConfirmChanges(userDetails.displayName, userDetails.email, userDetails.roles, userDetails.uid)
      .catch((error) => {
        setIsLoading(false)
      })
    } else {
      onConfirmChanges(userDetails.displayName, userDetails.email, userDetails.roles, userDetails.password)
      .catch((error) => {
        setIsLoading(false)
      })

    }
  }

  const handleOnBlur = () => {
    if(
      (userDetails?.displayName !== '') && 
      (userDetails?.email !== '' && userDetails?.email?.includes('@')) && 
      (userDetails?.password?.length > 5)) {
      setError(false)

    } else {
      setError(true)
    }
  }

  const handleOnBlurEdit = () => {
    if(
      (userDetails?.displayName !== '') &&
      (userDetails?.email !== '' && userDetails?.email?.includes('@'))
    ){
      setError(false)
    } else {
      setError(true)
    }
  }

  const checkboxSelectors = generateRoleSelectors(userDetails.roles, handleRoleChange, userClaims)

  return (
    <Grid>
      <DialogTitle id="form-dialog-title">{edit ? 'Edit User' : 'Add new member'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid container item>
            <TextField
              helperText="Display name cannot be empty"
              label="Display Name"
              margin="normal"
              name="displayName"
              sx={{ flexGrow: 1 }}
              value={userDetails.displayName}
              onBlur={ edit ? handleOnBlurEdit : handleOnBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid container item style={{ paddingTop: '0px' }}>
            <TextField
              helperText="Email cannot be empty"
              label="Email"
              margin="normal"
              name="email"
              sx={{ flexGrow: 1 }}
              value={userDetails.email}
              onBlur={ edit ? handleOnBlurEdit : handleOnBlur}
              onChange={handleChange}
            />
          </Grid>
          {!edit && (
            <Grid container item style={{ paddingTop: '0px' }}>
              <TextField
                autoComplete='new-password'
                helperText="Password must contain six characters*"
                label="Password"
                margin="normal"
                name="password"
                sx={{ flexGrow: 1 }}
                value={userDetails.password}
                onBlur={ edit ? handleOnBlurEdit : handleOnBlur}
                onChange={handleChange}
              />
            </Grid>
          )}
          <Grid container item>
            {checkboxSelectors}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancelChanges}>
          Cancel
        </Button>
        <LoadingButton disabled={error} loading={isLoading} onClick={handleConfirm}>
          Save
        </LoadingButton>
      </DialogActions>
    </Grid>
  )
}

export default EditPopup
