import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMap from '../components/GoogleMap';
import { BrandContext } from '../context/BrandContext/context';
import GoogleSearchBox from '../components/GoogleSearchBox';
import GoogleAutocompleteWidget from '../components/GoogleAutocompleteWidget';
import OverlayLayerSelector from '../components/OverlayLayerSelector';
import WMSOverlay from '../services/WMSOverlay'
import Utils from '../utils/Utils';
import Geolocation from '../services/Geolocation';
import LayerProviderListContent from '../components/MarkerContent/LayerProviderListContent';
import TowerContent from '../components/MarkerContent/TowerContent';
import { SiteContext } from '../context/SiteContext/context';
import fetchJsonp from 'fetch-jsonp';
import DrawLine from '../services/DrawLine';
import { Paper, TextField, Typography } from '@material-ui/core';
import { DataContext } from '../context/DataContext/context';
import LayerListContent from '../components/MarkerContent/LayerListContent';
import PoPContent from '../components/MarkerContent/PoPContent';
import CoverageMapContainer from './CoverageMapContainer';
import CoverageMapComponent from '../components/CoverageMapComponent';
import { head } from 'lodash';
import FeasibilityList from '../components/FeasibilityList';
import FileUploadComponent from "../components/FileUploadComponent";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import APIGateway from "../services/APIGateway";
// require('es6-promise').polyfill();
import firebase from 'firebase'

import Logging from '../services/Logging';

let GoogleDefaultInfoWindow = require('../utils/GoogleDefaultInfoWindow');
const ReactDOMServer = require('react-dom/server');
const currencyFormatter = Utils.formatCurrency()

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    rootNav: {
        height: '100%',
        display: 'flex'
    },
    fileUpload: {
        width: '30%',
        minWidth: '350px',
        textAlign: "center",
        margin: '10px',
        padding: '10pt',
        maxHeight: '95%',
    },
    feasibilityList: {
        width: '100%',
        padding: '10pt',
        margin: '10px',
        textAlign: "center",
        overflow: 'auto',
        maxHeight: '95%',
    },
    notes: {
        maxHeight: '70%',
        overflow: 'auto',
        marginBottom: '10pt',
        marginTop: '10pt',
    },
    paper: {
        padding: "10px",
        margin: '10px',
        textAlign: "center",
    }
}));

export default function BulkFeasibilityContainer(props) {
    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const { data, dispatchData } = useContext(DataContext)
    const classes = useStyles()
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
    });

    const [headers, setHeaders] = useState([
        'Network',
        'Bandwidth',
        'Term',
        'A: Latitude',
        'A: Longitude',
        'A: PoP',
        'A: PoP Latitude',
        'A: PoP Longitude',
        'A: LoS (m)',
        'A: Built Distance (m)',
        'B: Latitude',
        'B: Longitude',
        'B: PoP',
        'B: PoP Latitude',
        'B: PoP Longitude',
        'B: LoS (m)',
        'B: Built Distance (m)',
        'Total NRC',
        'Total MRC',
        'Comments',
    ])
    const [columns, setColumns] = useState([
        'Site ID',
        'Network',
        'Bandwidth',
        'Term',
        'A: Latitude',
        'A: Longitude',
        'A: PoP',
        'A: PoP Latitude',
        'A: PoP Longitude',
        'A: LoS (m)',
        'A: Built Distance (m)',
        'B: Latitude',
        'B: Longitude',
        'B: PoP',
        'B: PoP Latitude',
        'B: PoP Longitude',
        'B: LoS (m)',
        'B: Built Distance (m)',
        'Total NRC',
        'Total MRC',
        'Comments',
    ])
    const [items, setItems] = useState([])
    // const [feasibility, setFeasibility] = useState()
    const [loading, setLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [fileData, setFileData] = useState({})
    const [fileInfo, setFileInfo] = useState({})
    const [processing, setProcessing] = useState(false)
    const [canStart, setCanStart] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [csvdata, setCSVData] = useState(false)
    const [formattedAddress, setFormattedAddress] = useState('')
    const [errorState, setErrorState] = useState('')
    const [progress, setProgress] = useState(0)


    const providerNames = {
        'wioccmetrolines': 'WIOCC Metro Lines',
        'wioccnationallines': 'WIOCC National Lines',
        'wioccpops': 'WIOCC PoPs'
    }

    useEffect(() => {
        // prepareColumns()
    }, [])

    const prepareColumns = () => {
        setLoading(true)
        if (!headers || head.length === 0) return;
        let newHeaders = headers
        let newColumns = columns
        if (!headers.includes('address')) {
            newHeaders.push('address')
            setHeaders(newHeaders)
        }

        newHeaders.forEach(header => {
            if (header.toLowerCase() !== "service" && header.toLowerCase() !== "slug" && header.toLowerCase() !== "name" && header !== 'address')
                newColumns.push({ label: header, name: header.replace(/\s/g, '') });
            else if (header === 'address')
                newColumns.push({
                    label: header,
                    name: header.replace(/\s/g, ''),
                    options: { display: false, empty: true }
                });
        });
        setColumns(newColumns)
        setLoading(false)
    }


    const prepareItems = (feasibility) => {
        setLoading(true)
        console.log('feasibility!!!!!!!!!!!!!!!!!!!!', feasibility)
        let newItems = []

        setItems(correctItem(feasibility))
        setLoading(false)
    }

    const correctItem = (items) => {
        if (!items) return
        let newItems = []

        items.forEach(feasibilityItem => {
            let siteid = feasibilityItem.siteid;

            // if (!feasibilityItem.success || !feasibilityItem.feasible) {
            const itemObject = {
                'Site ID': siteid,
                // 'Network': feasibilityItem.product ? feasibilityItem.product : 'Not Feasible',
                'Network': feasibilityItem.product ? feasibilityItem.product : 'Not Feasible',
                'Bandwidth': feasibilityItem.bandwidth ? feasibilityItem.bandwidth : '',
                'Term': feasibilityItem.term ? feasibilityItem.term : '',
                'A: Latitude': feasibilityItem.siteA && feasibilityItem.siteA.location ? feasibilityItem.siteA.location.latitude : '',
                'A: Longitude': feasibilityItem.siteA && feasibilityItem.siteA.location ? feasibilityItem.siteA.location.longitude : '',
                'A: PoP': feasibilityItem.siteA && feasibilityItem.siteA.pop ? feasibilityItem.siteA.pop.name : 'Not Feasible',
                'A: PoP Latitude': feasibilityItem.siteA && feasibilityItem.siteA.pop && feasibilityItem.siteA.pop.location ? feasibilityItem.siteA.pop.location.latitude : '',
                'A: PoP Longitude': feasibilityItem.siteA && feasibilityItem.siteA.pop && feasibilityItem.siteA.pop.location ? feasibilityItem.siteA.pop.location.longitude : '',
                'A: LoS (m)': feasibilityItem.siteA && feasibilityItem.siteA.los ? parseFloat(feasibilityItem.siteA.los.toFixed(3)) : '',
                'A: Built Distance (m)': feasibilityItem.siteA && feasibilityItem.siteA.built_distance ? parseFloat(feasibilityItem.siteA.built_distance.toFixed(3)) : '',
                'B: Latitude': feasibilityItem.siteB && feasibilityItem.siteB.location ? feasibilityItem.siteB.location.latitude : '',
                'B: Longitude': feasibilityItem.siteB && feasibilityItem.siteB.location ? feasibilityItem.siteB.location.longitude : '',
                'B: PoP': feasibilityItem.siteB && feasibilityItem.siteB.pop ? feasibilityItem.siteB.pop.name : 'Not Feasible',
                'B: PoP Latitude': feasibilityItem.siteB && feasibilityItem.siteB.pop && feasibilityItem.siteB.pop.location ? feasibilityItem.siteB.pop.location.latitude : '',
                'B: PoP Longitude': feasibilityItem.siteB && feasibilityItem.siteB.pop && feasibilityItem.siteB.pop.location ? feasibilityItem.siteB.pop.location.longitude : '',
                'B: LoS (m)': feasibilityItem.siteB && feasibilityItem.siteB.los ? parseFloat(feasibilityItem.siteB.los.toFixed(3)) : '',
                'B: Built Distance (m)': feasibilityItem.siteB && feasibilityItem.siteB.built_distance ? parseFloat(feasibilityItem.siteB.built_distance.toFixed(3)) : '',
                'Total NRC': `${feasibilityItem?.siteA?.currency?.symbol || ''}${currencyFormatter.format(feasibilityItem?.total_nrc || 0) }`,
                'Total MRC': `${feasibilityItem?.siteA?.currency?.symbol || ''}${currencyFormatter.format(feasibilityItem?.total_mrc || 0) }`,
                'Comments': feasibilityItem.message ? feasibilityItem.message : ''
            }
            newItems.push(itemObject)
            if(feasibilityItem.feasible) {
                if (siteid && feasibilityItem.siteA.location.latitude && feasibilityItem.siteA.location.longitude) {
                    const loggingRequestObj = { 
                        siteid: siteid, 
                        siteA: {
                            latitude: feasibilityItem.siteA.location.latitude ? `${feasibilityItem.siteA.location.latitude.toFixed(6)}` : '', 
                            longitude: feasibilityItem.siteA.location.longitude ? `${feasibilityItem.siteA.location.longitude.toFixed(6)}` : '',
                        },
                        siteB: {
                            latitude: feasibilityItem.siteB.location.latitude ? `${feasibilityItem.siteB.location.latitude.toFixed(6)}` : '',
                            longitude: feasibilityItem.siteB.location.longitude ? `${feasibilityItem.siteB.location.longitude.toFixed(6)}` : '',
                        },
                        bandwidth: Utils.dataSizeParsing(feasibilityItem.requestData.bandwidth),
                        term: `${feasibilityItem.requestData.term} Months`,
                        // location: feasibilityItem.siteA.location.latitude ? feasibilityItem.siteA.location.latitude : '' + ', ' + feasibilityItem.siteA.location.longitude ? feasibilityItem.siteA.location.longitude : '' , 
                        action: 'bulk-feasibility' }
                    Logging.auditLog(loggingRequestObj, { results: [itemObject] })
                }
            }
            

            if (siteid && !feasibilityItem.feasible) {
                const loggingRequestObj = {
                    siteid: siteid,
                    siteA: feasibilityItem.requestData.siteA,
                    siteB: feasibilityItem.requestData.siteB,
                    bandwidth: Utils.dataSizeParsing(feasibilityItem.requestData.bandwidth),
                    term: `${feasibilityItem.requestData.term} Months`,
                    action: 'bulk-feasibility'
                 }
                Logging.auditLog(loggingRequestObj, { results: [itemObject] })

            }
            
            

        })

        

        return newItems;
    }

    const mapProviderName = (provider) => {
        if (providerNames[provider]) return providerNames[provider]
        return provider
    }

    const onAddressChange = (place) => {
        if (!place) return
        if (place.formatted_address) setFormattedAddress(place.formatted_address)
        else setFormattedAddress(place)
    }

    const onResultsReceived = async (results) => {
        // prepareColumns(results)
        prepareItems(results)
    }

    const handleClick = (event) => {
        document.getElementById("hiddenFileInput").click();
    };

    const handleDownloadBulkTemplateClick = (event) => {
        document.getElementById("hiddenDownloadBulkTemplate").click();
    };

    // useEffect(() => {
    //     setProgress(APIGateway.progress)
    //     console.log("THIS IS THE PROGRESSSSS WE MUST SET", APIGateway.progress)
    //     return () => {
    //         APIGateway.progress = 0
    //         setProgress(0)
    //     }
    // }, [APIGateway.progress])

    const handleStart = async (event) => {
        if (canStart && csvdata) {
            setUploading(false)
            setProcessing(true)
            setCanStart(false)
            setLoading(true)
            let userEmail = ''
            if (firebase.auth().currentUser && firebase.auth().currentUser.email) {
                userEmail = firebase.auth().currentUser.email
            }
            let results = await APIGateway.getData(csvdata, userEmail);
            console.log('results!!!!!!!!!!!!!!!!!!!!', results)
            console.log('results!!!!!!!!!!!!!!!!!!!!', ...results)
            prepareItems(results);
            setProcessing(false)
            setLoading(false)
            setFileInfo(undefined)
        }
    };

    const handleCancel = (event) => {
        setUploading(false)
        setProcessing(false)
        setCanStart(false)
        setLoading(false)
        setFileInfo(undefined)
    };

    const handleOnError = (err, file, inputElem, reason) => {
        console.log('Error in CSV Upload:', err);
        setErrorState(`CSV Upload Eror: ', ${err}`);
        // setUploading(false)
        setProcessing(false)
        setCanStart(false)
        setLoading(false)
        setFileInfo(undefined)
    };

    const handleOnFileLoad = async (csvdata, fileInfo) => {
        setUploading(true)
        setLoading(true)
        setErrorState('')
        setCSVData(undefined)
        setItems([])
        setFileInfo(fileInfo)

        if (!csvdata || csvdata.length === 0) {
            setErrorState('File Format Error: cannot upload an empty file.')
            setUploading(false)
            setProcessing(false)
            setCanStart(false)
            setLoading(false)
            setFileInfo(undefined)
        } else if (
            csvdata[0].hasOwnProperty('siteid') &&
            csvdata[0].hasOwnProperty('sitea_longitude') &&
            csvdata[0].hasOwnProperty('sitea_latitude') &&
            csvdata[0].hasOwnProperty('siteb_longitude') &&
            csvdata[0].hasOwnProperty('siteb_latitude') &&
            csvdata[0].hasOwnProperty('bandwidth') &&
            csvdata[0].hasOwnProperty('term')
        ) {
            if (csvdata.length <= brand.maxBulkLocations) {
                setCanStart(true)
                setUploading(false)
                setLoading(false)
                setCSVData(csvdata)
            } else {
                setCanStart(false)
                setUploading(false)
                setLoading(false)
                setErrorState('File Amount Error: Please upload a maximum of 5000 locations.')
            }
        } else if (!csvdata[0].hasOwnProperty('siteid')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: siteid column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('sitea_longitude')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: sitea_longitude column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('sitea_latitude')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: sitea_latitude column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('siteb_longitude')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: siteb_longitude column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('siteb_latitude')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: siteb_latitude column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('bandwidth')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: bandwidth column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('term')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: term column not found in file.')
        }

    };

    return (
        <div className={brand.navbar.show && brand.fullHeight ? classes.rootNav : classes.root}>
            <Paper className={classes.fileUpload}>
                <FileUploadComponent
                    title={'Bulk Feasibility'}
                    downloadTemplate={'siteid,sitea_latitude,sitea_longitude,siteb_latitude,siteb_longitude,bandwidth,term'}
                    downloadTemplateHeading={'Download Bulk Template File'}
                    handleOnError={handleOnError}
                    handleOnFileLoad={handleOnFileLoad}
                    isDisabled={isDisabled}
                    processing={processing}
                    uploading={uploading}
                    canStart={canStart}
                    errorState={errorState}
                    handleStart={handleStart}
                    handleCancel={handleCancel}
                    fileInfo={fileInfo}>
                    <div className={classes.notes}>
                        <Box>
                            <Divider />
                            <Typography className={classes.title} color="textSecondary" variant="subtitle1" gutterBottom style={{ paddingTop: "8px" }}>
                                How to use the bulk feasibility
                            </Typography>
                            <Divider />
                            {/* <Typography color="textSecondary" variant="caption"> */}
                            <List dense={true}>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        <strong>Follow the below instructions to upload a bulk file for multiple feasibility checks.</strong>
                                    </Typography>
                                </ListItem>
                                <ListItem></ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Step 1:
                                        <br></br>
                                            Start by downloading the CSV template here and populate the file with a site id, site A latitude, site A longitude, site B latitude, site B longitude bandwidth and term.
                                        <br></br> 
                                        <br></br> 
                                            Please note that the bandwidth values should all be input as megabytes e.g. 1 Gb equals a value of 1000 (Mb).
                                        <br></br> 
                                        <br></br> 
                                            Please note that the term values represent months and should be input as numerical values e.g. 24 equals 24 Months.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Step 2:<br></br>Add all your sites to the CSV file with a unique site id, latitude and longitude and save it locally to your device.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Step 3:<br></br>Click on the 'Upload file' button and select the relevant file.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Step 4:<br></br>Select the 'Start Feasibility' button.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Step 5:<br></br>Wait for the system to process the file and the results will display accordingly.
                                    </Typography>
                                </ListItem>
                                <ListItem></ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        To ensure your file runs successfully take note of the below:
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        <ul style={{ paddingLeft: "18px" }}>
                                            <li>The file must be uploaded in CSV format. </li>
                                            <li>Make sure to use the template format as downloaded, do not change the headings.</li>
                                            <li>Ensure you are using decimal degrees geographical coordinates for latitude and longitude, for more information please see <a href="http://wiki.gis.com/wiki/index.php/Decimal_degrees" target='_blank' rel="noreferrer" style={{ color: "rgba(0, 0, 0, 0.54)", fontStyle: "oblique" }}>this</a> article.</li>
                                            <li>The maximum number of locations is 5000.</li>
                                            <li>Once the bulk has started, the process can't be stopped until the results have finished processing. Keep the browser window open until the process has finished.</li>
                                            <li>Avoid using comma's (,) in the "siteid" field.</li>
                                        </ul>
                                    </Typography>
                                </ListItem>
                                {/* <ListItem
                                    component="a"
                                    href="https://youtu.be/ht6a-X3arkM"
                                    target="_blank"
                                >
                                    <Typography color="textSecondary" variant="caption">
                                        <em>Click here to watch the tutorial</em>
                                    </Typography>
                                </ListItem> */}
                            </List>
                            {/* </Typography>  */}
                        </Box>
                    </div>
                </FileUploadComponent>
            </Paper>
            <Paper className={classes.feasibilityList}>
                <FeasibilityList
                    columns={columns}
                    data={items}
                    loading={loading}
                    formattedAddress={formattedAddress}
                />
            </Paper>
        </div>
    );
}
