/* eslint-disable linebreak-style */
import axios from 'axios';

const WIOCCService = {};
// Expect data object:
// { lat, lng, apikey }

// USE /create path | returns jobid
// use if you didnt add locations to the create use addTo /locations
// use /start to start job
// use /getResults to get result (jobid, )

WIOCCService.doRequest = (data) => {
  let { siteid, latitude, longitude, bandwidth, term, error, userEmail } = data;
  //need to have this as .env variable
  let apiKey = 'AIzaSyDKosoNTNsG4rK_2C69cemkg3D8tMccz0I';
  const url = process.env.REACT_APP_CUSTOM_API_URL + '/getfeasibility'
  const requestData = {
     siteA: {
          latitude: data.sitea_latitude ? `${data.sitea_latitude.toFixed(6)}` : '', 
          longitude: data.sitea_longitude ? `${data.sitea_longitude.toFixed(6)}` : '',
      },
      siteB: {
          latitude: data.siteb_latitude ? `${data.siteb_latitude.toFixed(6)}` : '',
          longitude: data.siteb_longitude ? `${data.siteb_longitude.toFixed(6)}` : '',
      },
    bandwidth: bandwidth,
    term: term
  }

  if (error) {
    return {

    }
  }
  let response = axios
    .get(url, {
      params: {
        'sitea_latitude': data.sitea_latitude,
        'sitea_longitude': data.sitea_longitude,
        'siteb_latitude': data.siteb_latitude,
        'siteb_longitude': data.siteb_longitude,
        key: apiKey,
        channel: `${process.env.REACT_APP_CHANNEL}-bulk-${userEmail}`,
        bandwidth: bandwidth,
        term: term
      },
    })
    .then((response) => {
      // console.log('response', response);
      return WIOCCService.handleRequestSuccess(response, siteid, requestData);
    })
    .catch((error) => {
      // console.log('error', error);
      return WIOCCService.handleRequestError(error, siteid);
    });

  return response
};

WIOCCService.handleRequestSuccess = (response, siteid, data) => {
  // console.log('WIOCCService.handleRequestSuccessresponse: ', response);

  let responseData = response.data;
  let requestArray = [];

  requestArray.push({
    requestData: data,
    siteid: siteid,
    ...responseData
  });

  return requestArray;
};

WIOCCService.handleRequestError = (error, siteid) => {
  return {
    siteid: siteid,
    success: false,
    error: `error: ${error}`,
    services: [],
  };
};

WIOCCService.handleNodeRequest = (response) => {
  const {status, data} = response

  if(status === 200) {
    return data.nodes
  }
}

WIOCCService.handleSelectionSuccess = (response) => {
  const {status, data} = response
  
  if(status === 200) {
    return data.selections
  }
}

WIOCCService.getAggrigationNodes = (data) => {
  const {region} = data

  const url = process.env.REACT_APP_AGGRIGATION_NODES_API

  const response = axios
    .get(url,{
      params: {
        region
      }
    })
    .then((response) => {
      return WIOCCService.handleNodeRequest(response)
    })
    .catch((error) => {
      return WIOCCService.handleRequestError(error, '')
    })

  return response
}

WIOCCService.getSelections = (data) => {
  const {region} = data

  const url = process.env.REACT_APP_SELECTIONS_URL

  const response = axios
    .get(url,{
      params: {
        region
      }
    })
    .then((response) => {
      return WIOCCService.handleSelectionSuccess(response)
    })
    .catch((error) => {
      return WIOCCService.handleRequestError(error, '')
    })

  return response
}

export default WIOCCService;
