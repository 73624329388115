/* eslint-disable no-restricted-syntax */
/* eslint-disable linebreak-style */
/* eslint-disable no-trailing-spaces */

// import { map, of, Observable, Subscriber } from 'rxjs/operators';
import Bottleneck from 'bottleneck';

// import MicrowaveService from './APIs/MicrowaveService';
import CoverageService from './APIs/CoverageService';
import ClosestLineService from './APIs/ClosestLineService';
import LinkAfricaService from './APIs/LinkAfricaService';
import WIOCCService from "./APIs/WIOCCService";
import Utils from '../utils/Utils';

const APIGateway = {};

const coverageLimiter = new Bottleneck({
    maxConcurrent: 40,
    minTime: 40,
});

const wioccThrottler = coverageLimiter.wrap(WIOCCService.doRequest);
// const linkAfricaThrottler = coverageLimiter.wrap(LinkAfricaService.doRequest);
// const linesThrottler = coverageLimiter.wrap(ClosestLineService.doRequest)
// const comsolThrottler = coverageLimiter.wrap(ComsolService.doRequest)
// const microwaveThrottler = coverageLimiter.wrap(MicrowaveService.doRequest)
// const popsThrottler = coverageLimiter.wrap(PopsService.doRequest)
// const seacomThrottler = coverageLimiter.wrap(SeacomService.doRequest)
// const frogfootThrottler = coverageLimiter.wrap(FrogfootService.doRequest)

coverageLimiter.on('failed', async (error, jobInfo) => {
    const id = jobInfo.options.id;
    console.warn(`Job ${id} failed: ${error}`);

    if (jobInfo.retryCount < 3) {
        // Here we only retry once
        console.log(`Retrying job ${id} in 50ms!`);
        return 50;
    }
});

coverageLimiter.on('retry', (error, jobInfo) =>
    console.log(`Now retrying ${jobInfo.options.id}`)
);


APIGateway.getFeasibility = async (locationData, email) => {
    if (locationData.length <= 0) return;


    let requestArray = [];
    let errorArray = []
    const feasibilityArray = locationData
        .filter(data => !data.error)
        .map((data) => {
            data.userEmail = email
            return wioccThrottler(data)

        });

    // console.log('promise.all() | values', values);

    requestArray.push(
        ...feasibilityArray
    );

    let results = await APIGateway.allProgress(requestArray, (progress) => {
        console.log(`Progress | ${progress.toFixed(2)}%`);
    }).then((values) => {
        console.log('promise.all() | values', values);
        return values;
    });


    return results;
};

APIGateway.processResponse = async (feasibilityData) => {

    console.log('[[[[[[[[[[[[[[[[[[APIGateway.processResponse]]]]]]]]]]]]]]]]]]');
    console.log(
        'APIGateway.processResponse = async(feasibilityData',
        feasibilityData
    );

    if (!feasibilityData) return []
    let responsesArray = [];


    for (const response of feasibilityData) {
        if (response) {
            if (Array.isArray(response)) {
                responsesArray = responsesArray.concat(response);
            } else {
                responsesArray.push(response);
            }
        }
    }

    console.log('APIGateway.processResponse responsesArray', responsesArray);
    console.log('[[[[[[[[[[[[[[[[[[]]]]]]]]]]]]]]]]]]');

    let productsArray = []

    return responsesArray
};

APIGateway.formatCoords = (value) => {
    if (!value) return false

    let newVal = value
    if (typeof newVal === 'string')
        newVal = value.replaceAll(',', '.')

    const excelVar = /[^\x00-\x7F]/g

    if ((excelVar.test(newVal[0]) || newVal[0] === '`') && newVal[1] === '-') {
        newVal = newVal.substring(1)
    }

    if (isNaN(newVal)) return false

    newVal = parseFloat(newVal)

    return newVal
}

APIGateway.verifyData = async (locationData) => {

    // console.log('API Gateway | Verify Data', locationData)

    return locationData.reduce((result, data) => {
        let { siteid, term, bandwidth, sitea_longitude, sitea_latitude, siteb_latitude, siteb_longitude } = data;

        let formattedSiteALat = APIGateway.formatCoords(sitea_latitude)
        let formattedSiteALong = APIGateway.formatCoords(sitea_longitude)
        let formattedSiteBLat = APIGateway.formatCoords(siteb_latitude)
        let formattedSiteBLong = APIGateway.formatCoords(siteb_longitude)
        data.error = ''

        // console.log('API Gateway | verify data | after formatcoords', formattedSiteALat )
        // console.log('API Gateway | verify data | after formatcoords', formattedSiteALong )

        if (!siteid || siteid === '') {
            console.log(`Error at siteID: Invalid siteid or no siteid provided`);
            data.error += "Invalid siteid & "
            data.success = false
            data.products = []
        } if (!term || term === '') {
            console.log(`Error at term: Invalid or no term provided at siteID: ${siteid}`);
            data.error += "Invalid term & "
            data.success = false
            data.products = []
        } if (!bandwidth || bandwidth === '') {
            console.log(`Error at bandwidth: Invalid or no bandwidth provided at siteID: ${siteid}`);
            data.error += "Invalid bandwidth & "
            data.success = false
            data.products = []

            // validate coords
        } if ((formattedSiteBLat && formattedSiteBLong) && Utils.validateCoords(formattedSiteBLat, formattedSiteBLong)) {
            console.log(`Error at siteID:${siteid}: Invalid siteB_latitude or siteB_longitude. (lat: ${formattedSiteBLat}, lng: ${formattedSiteBLong})`);
            data.error += "Invalid siteB_latitude or siteB_longitude & "
        } if (Utils.validateCoords(formattedSiteALat, formattedSiteALong)) {
            console.log(`Error at siteID:${siteid}: Invalid siteA_latitude or siteA_longitude. (lat: ${formattedSiteALat}, lng: ${formattedSiteALong})`);
            data.error += "Invalid siteA_latitude or siteA_longitude & "
        }
        // no lat or long
        // } else if (!formattedSiteALat || !formattedSiteALong || !formattedSiteBLat || !formattedSiteBLong) {
        if (!formattedSiteALat) {
            console.log(`Error at siteID: ${siteid}: Invalid siteA_latitude. (lat siteA: ${formattedSiteALat})`);
            data.error += `Invalid siteA_latitude & `
            data.success = false
            data.products = []
        } if (!formattedSiteALong) {
            console.log(`Error at siteID: ${siteid}: Invalid siteA_longitude. (lng siteA: ${formattedSiteALong})`);
            data.error += `Invalid siteA_longitude & `
            data.success = false
            data.products = []
        } if(!formattedSiteBLat && formattedSiteBLong) {
            console.log(`Error at siteID: ${siteid}: Invalid siteA_longitude. (lng siteA: ${formattedSiteALong})`);
            data.error += `Valid siteb_longitude but invalid siteb_latitude`
            data.success = false
            data.products = []
        } if(formattedSiteBLat && !formattedSiteBLong) {
            console.log(`Error at siteID: ${siteid}: Invalid siteA_longitude. (lng siteA: ${formattedSiteALong})`);
            data.error += `Valid siteb_latitude but invalid siteb_longitude`
            data.success = false
            data.products = []
        }
        // }

        data.sitea_latitude = formattedSiteALat
        data.sitea_longitude = formattedSiteALong
        data.siteb_latitude = formattedSiteBLat || null
        data.siteb_longitude = formattedSiteBLong || null;

        if (data.error && data.error != '') {
            data.error = data.error.slice(0, -2)
        }

        result.push(data)
        return result;
    }, []);
};

APIGateway.getData = async (locationData, email) => {

    if (!locationData) return;

    const verifiedData = await APIGateway.verifyData(locationData);
    console.log('API Gateway | verified Data:', verifiedData);
    console.log('API Gateway | location data:', locationData);

    //here its checking twice
    const results = await APIGateway.getFeasibility(verifiedData, email).then((data) => {
        return data;
    });
    console.log('API Gateway | results:', results);
    console.log('API Gateway | results | verifieddata:', verifiedData);

    let processedResults = await APIGateway.processResponse(results);
    console.log('API Gateway | before processedresults:', processedResults);

    if (locationData && locationData.length > 0) {
        locationData.forEach(location => {

            if (location.error) {
                processedResults.push({
                    siteid: location.siteid,
                    product: 'Error',
                    siteA: {
                        pop: {
                            name: ''
                        }
                    },
                    siteB: {
                        pop: {
                            name: ''
                        }
                    },
                    message: location.error,
                })
            }
        })
    }

    console.log('API Gateway | after processedresults:', processedResults);

    return processedResults;
};

APIGateway.allProgress = (promiseArray, progress_cb) => {
    let dataCount = 0;
    progress_cb(0);
    for (const currentPromise of promiseArray) {
        currentPromise.then(() => {
            dataCount++;
            progress_cb((dataCount * 100) / promiseArray.length);
        });
    }
    return Promise.all(promiseArray);
}

APIGateway.test = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            console.log(`Waited ${ms}`);
            resolve();
        }, ms);
    });
}

export default APIGateway;
