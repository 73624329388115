import React, { useState, useCallback, useEffect } from 'react'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import LockResetIcon from '@mui/icons-material/LockReset'
import NoAccountsIcon from '@mui/icons-material/NoAccounts'
import { Box, Grid } from '@mui/material'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import { ToastContainer, toast } from 'react-toastify'

import CustomToolbar from '../components/UserManagement/CustomToolbar'
import Popup from '../components/UserManagement/UserManagementPopup'
import { useUserAuth } from '../context/AuthContext/AuthContext'
import { useUserAdmin } from '../services/UserAdminInterface'
import useAsync from '../utils/hooks/useAsync'
import 'react-toastify/dist/ReactToastify.css'

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .custom-row--disabled': {
    backgroundColor: '#F5F5F5',
    color: 'gray',
    '&:hover': {
      backgroundColor: '#e3e3e3',
      color: 'gray'
    }
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none'
  }
}))

const styles = {
  addUser: {
    marginRight: '15px',
    marginTop: '10px',
    color: '#f0f0f0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '35px',
    borderRadius: '25px'
  },
  actionsButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  actionButton: {
    maringLeft: '10px'
  },
  customToastBackground: {
    backgroundColor: 'primary'
  }
}

const columns = [
  { field: 'displayName', headerName: 'Display Name', flex: 0.5 },
  { field: 'email', headerName: 'Email', flex: 1 },
  {
    field: 'roles',
    headerName: 'Roles',
    flex: 0.5,
    sortable: false,
    renderCell: (rowParams) => {
      let chipArray = []

      if (rowParams?.row?.roles?.length) {
        chipArray = rowParams.row.roles.map((role) => {
          if (!(role in roleDict)) return null

          return (
            <Chip
              key={`${role}`}
              color={'primary'}
              label={roleDict[role]}
              size="small"
              sx={{ marginRight: '2px' }}
              variant="outlined"
            />
          )
        })
      }

      return chipArray
    }
  },
  { 
    field: 'created', 
    headerName: 'Created', 
    flex: 0.5, 
    type: 'date',
    valueGetter: (params) => {

      if(!params.row.created) return null

      const date = params.row.created

      const timeStamp = Date.parse(date)

      return new Date(timeStamp) || ''
    },
    valueFormatter: (params) => {

      if(!params.value || params.value === 'Invalid Date') return ''
      
      const dateArray = params.value.toDateString().split(' ')

      const date = `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`
      const time = params.value.toTimeString().split(' ')[0]

      return `${date} ${time}`
    }
  },
  { 
    field: 'lastSignIn', 
    headerName: 'Signed In', 
    flex: 0.5, 
    type: 'date',
    valueGetter: (params) =>{

      if(!params.row.lastSignIn) return null

      const date = params.row.lastSignIn

      const timeStamp = Date.parse(date)

      return new Date(timeStamp) || ''
    },
    valueFormatter: (params) => {
      
      if(!params.value || params.value === 'Invalid Date') return ''

      const dateArray = params.value.toDateString().split(' ')

      const date = `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`
      const time = params.value.toTimeString().split(' ')[0]

      return `${date} ${time}`
    }
  },
  {
    field: 'Actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (rowParams) => {
      if (rowParams?.row?.actions) return rowParams.row.actions
    }
  }
]

const roleDict = {
  user: 'User',
  admin: 'Admin',
  sysadmin: 'sysadmin',
}

const defaultRoles = {
  user: true,
  admin: false,
  sysadmin: false,
}

export default function UserManagementContainer() {
  const [rows, setRows] = useState([])
  const [editingUserDetails, setEditingUser] = useState({
    uid: '',
    email: '',
    roles: defaultRoles,
    displayName: '',
    disable: ''
  })

  const { getUserList, createUser, deleteUser, updateUser, disableUser } = useUserAdmin()
  const { execute: refreshUserList, status, value: userList, error } = useAsync(getUserList, true)
  const { user: currentUser, resetPasswordLink } = useUserAuth()

  const [popupOpen, setPopupOpen] = useState(false)
  const [popupType, setPopupType] = useState('edit')

  useEffect(() => {
    if (status === 'success' && userList) {

      const userListWithActions = addActionsToUserList(userList)

      setRows(userListWithActions)
    }
  }, [status, userList])

  useEffect(() => {
    if (error) {
      toast.error('Failed to fetch users. It might be a network issue.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [error])

  const addActionsToUserList = useCallback((userListWithoutActions) => {
    let dataArray = []

    dataArray = userListWithoutActions.map((user) => {
      let isLoggedInUser = false

      if (user?.email === currentUser?.email) {
        isLoggedInUser = true
      }

      const actionComponents = (
        <>
          <Tooltip title={user.provider === 'password' ? "Reset Password" : "Reset your password at your service provider"}>
            <span>
              <IconButton disabled={user.provider !== 'password'} style={styles.actionsButtons} onClick={() => handleResetPassword(user)}>
                <LockResetIcon />
              </IconButton>
            </span>
          </Tooltip>
          {user.isDisabled ? (
            <Tooltip title="Enable User">
              <span>
              <IconButton style={styles.actionsButtons} onClick={() => handleDisableMemeber(user)}>
                <AccountCircleIcon />
              </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Disable User">
              <span>
                <IconButton
                  disabled={isLoggedInUser}
                  style={styles.actionsButtons}
                  onClick={() => handleDisableMemeber(user)}
                  >
                  <NoAccountsIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Edit">
            <span>
              <IconButton style={styles.actionButton} onClick={() => handleEditMember(user)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
            <Tooltip title="Delete">
            <span>
              <IconButton disabled={isLoggedInUser} style={styles.actionButton} onClick={() => handleDeleteMemeber(user)}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )

      return {
        ...user,
        actions: actionComponents
      }
    })

    return dataArray
  }, [])

  const handleResetPassword = useCallback(async (user) => {
    resetPasswordLink(user.email)
      .then((response) => {
        toast.success('A password reset link has been sent to their email address.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
      .catch((error) => {
        toast.error(`A reset link could not be sent. Error: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
  }, [])

  const handleEditMember = (rowParams) => {
    setPopupType('edit')
    const roleObj = {}
    const userObject = {}

    if (rowParams?.roles?.length) {
      rowParams.roles.forEach((role) => {
        roleObj[role] = true
      })

      userObject.uid = rowParams.id
      userObject.email = rowParams.email
      userObject.roles = { ...defaultRoles, ...roleObj }
      userObject.displayName = rowParams.displayName
      userObject.disable = rowParams.isDisabled
    }

    setEditingUser(userObject)
    setPopupOpen(true)
  }

  const handleRegisterMember = (rowParams) => {
    setPopupType('create')
    const defaultUserObject = {
      uid: '',
      email: '',
      roles: defaultRoles,
      displayName: '',
      disable: false,
      password: ''
    }

    setEditingUser(defaultUserObject)
    setPopupOpen(true)
  }

  const handleDeleteMemeber = (rowParams) => {
    const userObject = {}

    userObject.uid = rowParams.id
    setEditingUser(userObject)

    setPopupOpen(true)
    setPopupType('delete')
  }

  const handleDisableMemeber = (rowParams) => {
    const userObject = {}

    if (!rowParams) return

    userObject.uid = rowParams.id
    userObject.disable = rowParams.isDisabled

    setEditingUser(userObject)
    setPopupOpen(true)
    setPopupType('disable')
  }

  const closeWindow = () => {
    setPopupOpen(false)
  }

  const onRegisterUser = async (displayName, email, roles, password = '') => {
    const rolesAsArray = Object.entries(roles)
    const rolesArray = []

    rolesAsArray.forEach(([key, value]) => {
      if (value) rolesArray.push(key)
    })

    await createUser(displayName, email, rolesArray, password)
      .then((createUserResult) => {
        refreshUserList()
        closeWindow()
        
        if(createUserResult?.data?.message) {
          toast.info(createUserResult?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            theme: 'colored'
          })
        } else {
          toast.success('The user has been successfully created.', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
        
      })
      .catch((error) => {
        toast.error(`The user could not be created. Error: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT
        })

        refreshUserList()
        closeWindow()
      })
  }

  const onEditUser = async (displayName, email, roles, uid) => {
    const rolesAsArray = Object.entries(roles)
    const rolesArray = []

    rolesAsArray.forEach(([key, value]) => {
      if (value) rolesArray.push(key)
    })

    await updateUser(uid, displayName, email, rolesArray)
      .then((updateResponse) => {
        closeWindow()
        toast.success('The user has been successfully updated.', {
          position: toast.POSITION.TOP_RIGHT
        })

        refreshUserList()
      })
      .catch((error) => {
        toast.error(`The user could not be updated. Error: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT
        })

        refreshUserList()
        closeWindow()
      })
  }

  const onDelete = useCallback(async (uid) => {
    await deleteUser(uid)
      .then(() => {
        closeWindow()
        toast.success('The user has been successfully deleted.', {
          position: toast.POSITION.TOP_RIGHT
        })

        refreshUserList()
      })
      .catch((error) => {
        toast.error(`The user could not be deleted. Error: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT
        })

        refreshUserList()
        closeWindow()
      })
  }, [])

  const onDisable = useCallback(async (uid, isDisable) => {
    let disable = ''

    if (isDisable) {
      disable = false
    } else disable = true

    await disableUser(uid, disable)
      .then((updateResponse) => {
        closeWindow()
        toast.success(
          isDisable ? 'The user has been successfully enabled.' : 'The user has been successfully disabled.',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        )

        refreshUserList()
      })
      .catch((error) => {
        toast.error(`The user could not be disabled. Error: ${error.message}`, {
          position: toast.POSITION.TOP_RIGHT
        })

        refreshUserList()
        closeWindow()
      })
  }, [])

  return (
    <Grid
      height="100%"
      rounded={7}
      sx={{
        borderRadius: '15px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        padding: '10px'
      }}
    >
      <ToastContainer />
      <Typography sx={{ paddingBottom: '10px' }} variant="h6">User Management</Typography>
      <Box height="calc(100% - 40px)">
        <StyledDataGrid
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          columns={columns}
          editMode="row"
          getRowClassName={(params) => (params.row.isDisabled ? `custom-row--disabled` : '')}
          rows={rows}
          slotProps={{
            toolbar: {
              onCreateUser: handleRegisterMember
            }
          }}
          slots={{ toolbar: CustomToolbar }}
          onRowClick={() => null}
        />
      </Box>
      <Popup
        editingUserDetails={editingUserDetails}
        open={popupOpen}
        popupType={popupType}
        onCancelChanges={closeWindow}
        onCreateUser={onRegisterUser}
        onDeleteUser={onDelete}
        onDisableUser={onDisable}
        onSaveUser={onEditUser}
      />
    </Grid>
  )
}
