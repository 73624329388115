import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { GridToolbarQuickFilter } from '@mui/x-data-grid'

function CustomToolbar({onCreateUser}) {

  

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        p: 2,
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <GridToolbarQuickFilter debounceMs={500}/>
      <Button
        sx={{ ml: 1 }}
        variant="outlined"
        onClick={onCreateUser}
        onMouseDown={onCreateUser}
      >
        Add User
      </Button>
    </Box>
  )
}

// CustomToolbar.propTypes = {
//   cellMode: PropTypes.oneOf(['edit', 'view']).isRequired,
//   cellModesModel: PropTypes.object.isRequired,
//   selectedCellParams: PropTypes.shape({
//     field: PropTypes.string.isRequired,
//     id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
//   }),
//   setCellModesModel: PropTypes.func.isRequired,
// }

export default CustomToolbar