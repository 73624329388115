import React from 'react'

import './App.css';
import PortalView from './views/PortalView';
import history from 'history/browser';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import UserAdminProvider from './services/UserAdminInterface';
import { BrandContext } from './context/BrandContext/context'
import { useContext, useEffect, useRef, useState } from 'react';
import { SiteContext } from './context/SiteContext/context';
import firebase from 'firebase/app';
import 'firebase/auth';
import LoginView from './views/LoginView';
import { useUserAuth } from './context/AuthContext/AuthContext';
import ForgotPasswordView from './views/ForgotPasswordView';
import ResetPasswordView from './views/ResetPasswordView';

function App() {
  const { brand } = useContext(BrandContext)

  const { user } = useUserAuth()

  const theme = createTheme({
    Sidebar: {
      color: 'black',
      textDecoration: 'none !important'
    },
    SidebarItem: {
      textDecoration: 'none !important'
    },
    BrandContainer: {
      textAlign: 'center',
    },
    palette: {
      primary: brand.color.primary,
      secondary: brand.color.secondary,
      disabled: brand.color.disabled,
      live: brand.wms && brand.wms.color && brand.wms.color.live ? brand.wms.color.live : brand.color.primary,
      comingsoon: brand.wms && brand.wms.color && brand.wms.color.comingsoon ? brand.wms.color.comingsoon : brand.color.secondary
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
        {!brand.authentication || user ?
          <UserAdminProvider>
            <Router history={history}>
              <Switch>
                <Route path='/' theme={theme} component={PortalView} key="PortalView"  />
              </Switch>
            </Router>
          </UserAdminProvider>
          :
          <Router history={history}>
            <Switch>
              <Route exact path='/' theme={theme} component={LoginView} key="LoginView"  />
              <Route path='/forgot-password' theme={theme} component={ForgotPasswordView} key="forgotPasswordView"  />
              <Route path='/reset-password' theme={theme} component={ResetPasswordView} key="ResetPassword"  />
            </Switch>
          </Router>
        }
      </MuiThemeProvider>
  );
}

export default App;

