import React, { useState } from 'react'

import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

function DisablePopup({ editingUserDetails, onCancelChanges, onConfirmChanges }) {
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = () => {
    setIsLoading(true)

    onConfirmChanges(editingUserDetails.uid, editingUserDetails.disable)
  }

  return (
    <Grid>
      <DialogTitle id="form-dialog-title">{editingUserDetails.disable ? 'Enable' : 'Disable'} User</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to {editingUserDetails.disable ? 'enable' : 'disable'} user?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onCancelChanges}>
          Cancel
        </Button>
        <LoadingButton loading={isLoading} onClick={handleConfirm}>
          {editingUserDetails.disable ? 'Enable' : 'Disable'}
        </LoadingButton>
      </DialogActions>
    </Grid>
  )
}

export default DisablePopup
