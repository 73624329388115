/* eslint-disable linebreak-style */
import axios from 'axios';

const CoverageService = {};
// Expect data object:
// { lat, lng, apikey }

// USE /create path | returns jobid
// use if you didnt add locations to the create use addTo /locations
// use /start to start job
// use /getResults to get result (jobid, )

CoverageService.doRequest = (data) => {
  let { siteid, latitude, longitude } = data;
  //need to have this as .env variable
  let apiKey = '410ffde9-7937-4db5-be35-371f205ec69b';
  const url = 'https://api.coverage.28east.co.za/getcoverage';
  return axios
    .get(url, {
      params: {
        latitude,
        longitude,
        key: apiKey,
        channel: 'LinkAfrica Bulk Feasibility',
      },
    })
    .then((response) => {
      // console.log('response', response);
      return CoverageService.handleRequestSuccess(response, siteid);
    })
    .catch((error) => {
      // console.log('error', error);
      return CoverageService.handleRequestError(error, siteid);
    });
};

CoverageService.handleRequestSuccess = (response, siteid) => {
  // console.log('CoverageService.handleRequestSuccessresponse: ', response);

  let responseData = response.data;
  let requestArray = [];

  const { services, success } = responseData;

  for (const { providers, type } of services) {
    if (Array.isArray(providers)) {
      for (const { provider, distance, status } of providers) {
        if (type === 'fibre' && provider === 'openserve') {
          requestArray.push({
            provider: 'openserveftth',
            feasibility: status,
            extra: [],
          });
        } else if (type === 'businessfibre' && provider === 'frogfoot') {
          //Filter out businessfibre frogfoot as we get it from their api which is more accurate
        } else {
          // console.log(
          //   'CoverageService.handleRequestSuccess distance: ',
          //   distance
          // );
          requestArray.push({
            provider,
            feasibility: status,
            extra: distance ? [distance] : [],
          });
        }
      }
    }
  }

  return {
    success,
    siteid,
    services: requestArray,
    error: false,
  };
};

CoverageService.handleRequestError = (error) => {
  return {
    success: false,
    error: `error: ${error}`,
    services: [],
  };
};

export default CoverageService;
