/* eslint-disable no-unused-vars */

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios'
import Bottleneck from 'bottleneck'

import { useUserAuth } from '../context/AuthContext/AuthContext'
// import { useUserAuth } from '../context/AuthContext/AuthContext'
import Utils from '../utils/Utils'

const UserAdminContext = createContext(null)

const rateLimiter = new Bottleneck({
  maxConcurrent: 3,
  minTime: 40
})

export const useUserAdmin = () => useContext(UserAdminContext)

function UserAdminProvider({ children }) {
  const { userClaims } = useUserAuth()

  const options = useCallback(() =>{  
    return { headers: { Authorization: `Bearer ${userClaims.accessToken}` } }
  }, [userClaims])

  const getUserList = useCallback(async () => {
    let url = `${process.env.REACT_APP_USERMANAGEMENT_URL}/users`

    if (process.env.REACT_APP_CONFIG_APP) {
      url += `/${process.env.REACT_APP_CONFIG_APP}`
    }
    
    if (userClaims?.accessToken) {
      return (
        axios
          .get(url, options())
          .then((response) => Utils.parseForAdminDataTable(response.data, userClaims))
          // .then((fullResponse) => Utils.parseForAdminDataTable(fullResponse.data))
          .catch((error) => {
            throw Error(`UserList API Error:' ${error}`)
          })
      )
    }

    return false
  }, [options, userClaims])

  const disableUser = useCallback(async (uid, isDisabled) => {
    let url = `${process.env.REACT_APP_USERMANAGEMENT_URL}/users/${uid}`

    if (process.env.REACT_APP_CONFIG_APP) {
      url += `/${process.env.REACT_APP_CONFIG_APP}`
    }
    
    const data = {
      disableUser: isDisabled
    }

    return axios
      .patch(url, data, options())
      .then((response) => response)
      .catch(error => {   
        if(error?.response?.data?.message) throw (error.response.data)

        throw Error(`disableUser API Error: ${error.message}`)
      })
  }, [options])

  const deleteUser = useCallback(async (uid) => {
    let url = `${process.env.REACT_APP_USERMANAGEMENT_URL}/users/${uid}`

    if (process.env.REACT_APP_CONFIG_APP) {
      url += `/${process.env.REACT_APP_CONFIG_APP}`
    }

    return axios
      .delete(url, options())
      .then((response) => response)
      .catch(error => {   
        if(error?.response?.data?.message) throw (error.response.data)

        throw Error(`deleteUser API Error: ${error.message}`)
      })
  }, [options])

  const updateUser = useCallback(async (uid, displayName, email, roles) => {
    const data = {
      displayName,
      email,
      roles,
      password: Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
    }

    let url = `${process.env.REACT_APP_USERMANAGEMENT_URL}/users/${uid}`

    if (process.env.REACT_APP_CONFIG_APP) {
      url += `/${process.env.REACT_APP_CONFIG_APP}`
    }
    
    return axios
      .patch(url, data, options())
      .then((response) => response)
      .catch(error => {   
        if(error?.response?.data?.message) throw (error.response.data)

        throw Error(`UpdateUser API Error: ${error.message}`)
      })
  }, [options])

  const createUser = useCallback(async (displayName, email, roles, password) => {
    const data = {
      displayName,
      email,
      roles,
      password: password || Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)
    }

    let url = `${process.env.REACT_APP_USERMANAGEMENT_URL}/users`

    if (process.env.REACT_APP_CONFIG_APP) {
      url += `/${process.env.REACT_APP_CONFIG_APP}`
    }
    
    return axios
      .put(url, data, options())
      .then((response) => response)
      .catch(error => {   
        if(error?.response?.data?.message) throw (error.response.data)

        throw Error(`createUser API Error: ${error.message}`)
      })
  }, [options])

  const providerFunctions = useMemo(
    () => ({
      createUser,
      getUserList,
      updateUser,
      disableUser,
      deleteUser
    }),
    []
  )

  return <UserAdminContext.Provider value={providerFunctions}>{children}</UserAdminContext.Provider>
}

export default UserAdminProvider
