import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import GreyMapStyle from '../../mapstyles/grey.mapstyle'
import DarkMapStyle from '../../mapstyles/dark.mapstyle'
import AssasinscreedMapStyle from '../../mapstyles/assasinscreed.mapstyle'
import DefaultMapStyle from '../../mapstyles/default.mapstyle'

export const BrandContext = createContext();

const BrandProvider = ({ children }) => {
    const [brand, dispatchBrand] = useReducer(reducer, {
        clientName: 'WIOCC',
        logo: "./logo.png",
        title: "",
        coverageAPI: {
            apikey: 'AIzaSyDKosoNTNsG4rK_2C69cemkg3D8tMccz0I',
            useCustomAPI: true
        },
        googlemaps: {
            apikey: 'AIzaSyAPFPxzyeKsg_AdwIIf1F4Ngh5fUflSeyQ',
            mapstyle: GreyMapStyle.style,
            center: { lat: -26, lng: 25 }, //{ lat: -26, lng: 25 }
            markerZoomLevel: 16, //can be omitted
        },
        authentication: true,
        firebaseConfig: {
            apiKey: "AIzaSyDKosoNTNsG4rK_2C69cemkg3D8tMccz0I",
            authDomain: "east-wiocc.firebaseapp.com",
            projectId: "east-wiocc",
            storageBucket: "east-wiocc.appspot.com",
            messagingSenderId: "283679485873",
            appId: "1:283679485873:web:cf91d46a01701763369790"
        },
        color: {
            primary: {
                light: '#1743b2',
                main: '#1C3E95',
                dark: '#193375',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff494f',
                main: '#EA2B33',
                dark: '#c91a20',
                contrastText: '#fff',
            },
            disabled: {
                light: '#ff494f',
                main: '#EA2B33',
                dark: '#c91a20',
                contrastText: '#fff',
            }
        },
        wms: {
            singleLayer: true,
            color: {
                live: {
                    main: '#1C3E95',
                    contrastText: '#fff',
                },
                comingsoon: {
                    main: '#1743b2',
                    contrastText: '#fff',
                }
            },
        },
        navbar: {
            show: true,
            position: 'fixed' //fixed, float
        },
        sidebar: {
            show: false
        },
        layerSelection:{
            collapsed: true,
            useSubheadings: false
        },
        fullHeight: true,
        maxBulkLocations: 5000,
        css: {}
    });

    return (
        <BrandContext.Provider value={{ brand, dispatchBrand }}>
            {children}
        </BrandContext.Provider>
    );
};

export default BrandProvider;
